import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import './layout.less';
import MainNav from './MainNav/MainNav';
import ChatAssistantBadge from './../features/chat/chatAssistantBadge';

const { Content } = Layout;

export default function AppLayout({ children, url }) {
	const {
		appStatus: { metaInfo: AppMetaInfo },
		auth: { user },
	} = useSelector(state => state);

	return (
		<Layout className="layout">
			<MainNav url={url} />
			{AppMetaInfo?.chat && !user?.avoidChat && <ChatAssistantBadge />}
			<Content className="layout-content">{children}</Content>
		</Layout>
	);
}
