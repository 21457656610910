import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as appStatus } from './appStatusSlice';
import { reducer as authReducer } from '../pages/Login/authSlice';
import { reducer as departmentHeaderReducer } from '../pages/Department/Header/headerSlice';
import { reducer as filterReducer } from '../pages/Department/Filters/filterSlice';
import { reducer as allTicketsReducer } from '../pages/Department/TicketsList/ticketListSlice';
import { reducer as departmentsStats } from '../features/department/statsSlice';
import { reducer as functionalitiesReducer } from '../pages/Department/Functionalities/functionalitiesSlice';
import { reducer as ticketDetailsReducer } from '../pages/TicketDetail/ticketDetailSlice';
import { reducer as forgotPassword } from '../pages/ForgotPass/forgotPassSlice';
import { reducer as userManagement } from '../pages/UserMng/userMngSlice';
import { reducer as communications } from '../features/communication/communicationsSlice';
import { reducer as callReducer } from '../features/communication/call/callSlice';
import { reducer as navigationReducer } from '../router/navigationSlice';
import { reducer as managementReducer } from '../pages/UserMng/Management/managementSlice';
import { reducer as emrReducer } from '../pages/TicketDetail/TabsAts/EmrTab/emrSlice';
import { reducer as copilotReducer } from '../features/chat/copilotSlice';
import { reducer as faceToFaceReducer } from '../pages/TicketDetail/TabsAts/FaceToFaceTab/faceToFaceSlice';
import { reducer as logHistoryReducer } from '../pages/TicketDetail/LogHistory/logHistorySlice';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
	router: connectRouter(history),
	auth: authReducer,
	departmentHeader: departmentHeaderReducer,
	departmentFilters: filterReducer,
	allTicketsReducer,
	functionalitiesReducer,
	ticketDetailsReducer,
	departmentsStats,
	forgotPassword,
	userManagement,
	navigationReducer,
	communications,
	call: callReducer,
	managementReducer,
	emrReducer,
	appStatus,
	copilot: copilotReducer,
	faceToFaceReducer,
	logHistoryReducer,
});

const persistConfig = {
	key: 'root',
	whitelist: ['auth'],
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const DEV_MIDDLEWARE_WARNING_TRESHOLD = 100;

const middlewares = [
	routerMiddleware(history),
	...getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'copilotReducer/setMessageListener', 'copilotReducer/setFunctionListener'],
			warnAfter: DEV_MIDDLEWARE_WARNING_TRESHOLD,
		},

		immutableCheck: {
			warnAfter: DEV_MIDDLEWARE_WARNING_TRESHOLD,
		},
	}),
];

const isNotProd = process.env.NODE_ENV !== 'production';

if (isNotProd) {
	//middlewares.push(logger);
}

export let store = configureStore({
	reducer: persistedReducer,
	middleware: middlewares,
	devTools: isNotProd,
});

export let persistor = persistStore(store);
