import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import dayjs from '../../../../utils/dateHandler';
import { DATE_FORMAT } from '../../../../utils/time';
import request from '../../../../utils/API/apiHandler';
import hhauLogo from '../../../../assets/images/ATS/orientation-hhau.png';
import { Col, Row } from 'antd';

const HhauType = ({ tabStageObj }) => {
	const { loginUrl, username, password } = tabStageObj;

	const [hhauTicket, setHhauTicket] = useState({});

	useEffect(() => {
		request({ url: `/tickets/hhau/${tabStageObj?.ticket?._id}` }).then(res => {
			const ticket = res?.ticket || {};
			setHhauTicket(ticket);
		});
	}, []);

	if (!Object.keys(hhauTicket).length) {
		return null;
	}

	const listTicketObjPaths = {
		'Training Type': 'type',
		Progress: 'progression.progress',
		'Last Course': 'lastCompletedCourse',
		'Last Chapter': 'lastCompletedChapter.name',
		'Chapter Attempt Date': 'lastCompletedChapter.completedAt',
		'Chapter Score': 'lastCompletedChapter.score',
	};

	const hhauLoginInfo = { 'Login Url': loginUrl, Username: username, Password: password };

	return (
		<div className="orientation-tab__hhau">
			<Row>
				<Col span={12}>
					<h1>HHAU</h1>
				</Col>
				<Col span={12}>
					<img className="logo" src={hhauLogo} />
				</Col>
			</Row>
			{Object.keys(listTicketObjPaths).map(key => {
				let value = get(hhauTicket, listTicketObjPaths[key], undefined);
				if (key === 'Chapter Attempt Date' && value !== 'No Data') value = dayjs(value).format(DATE_FORMAT);

				return (
					<div key={key} className="input-group">
						<span className="label">{key}</span>
						<span className="value">
							{value !== undefined && value !== null ? value : 'No Data'} {key === 'Progress' && value !== undefined && value !== null && '%'}
						</span>
					</div>
				);
			})}
			{Object.keys(hhauLoginInfo).map(key =>
				hhauLoginInfo[key] ? (
					<div key={key} className="input-group">
						<span className="label">{key}</span>
						<span className="value">{hhauLoginInfo[key] || 'No Data'}</span>
					</div>
				) : null,
			)}
		</div>
	);
};

export default HhauType;
