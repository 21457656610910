import React from 'react'

export const UserApproveIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 1.83325C6.80355 1.83325 5.8335 2.8033 5.8335 3.99992C5.8335 5.19654 6.80355 6.16659 8.00016 6.16659C9.19678 6.16659 10.1668 5.19654 10.1668 3.99992C10.1668 2.8033 9.19678 1.83325 8.00016 1.83325ZM4.8335 3.99992C4.8335 2.25102 6.25126 0.833252 8.00016 0.833252C9.74906 0.833252 11.1668 2.25102 11.1668 3.99992C11.1668 5.74882 9.74906 7.16659 8.00016 7.16659C6.25126 7.16659 4.8335 5.74882 4.8335 3.99992Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0002 8.5C10.8035 8.5 9.8335 9.47005 9.8335 10.6667C9.8335 11.8633 10.8035 12.8333 12.0002 12.8333C13.1968 12.8333 14.1668 11.8633 14.1668 10.6667C14.1668 9.47005 13.1968 8.5 12.0002 8.5ZM8.8335 10.6667C8.8335 8.91776 10.2513 7.5 12.0002 7.5C13.7491 7.5 15.1668 8.91776 15.1668 10.6667C15.1668 12.4156 13.7491 13.8333 12.0002 13.8333C10.2513 13.8333 8.8335 12.4156 8.8335 10.6667Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2366 9.71514C13.4347 9.90745 13.4395 10.224 13.2472 10.4222L12.0252 11.6815C11.9265 11.7832 11.7892 11.8383 11.6476 11.833C11.5059 11.8276 11.3732 11.7624 11.2824 11.6536L10.7264 10.9869C10.5495 10.7748 10.5781 10.4595 10.7901 10.2827C11.0022 10.1058 11.3175 10.1343 11.4943 10.3464L11.6945 10.5864L12.5295 9.72578C12.7218 9.5276 13.0384 9.52284 13.2366 9.71514Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99922 8.16654C8.70886 8.16457 9.4165 8.24173 10.109 8.39659L9.89081 9.37249C9.27061 9.2338 8.63687 9.16473 8.00135 9.16654L7.99993 9.16654C6.59689 9.16654 5.35123 9.48756 4.47389 9.98108C3.58086 10.4834 3.16659 11.0991 3.16659 11.6665C3.16659 12.5383 3.19347 13.0291 3.64899 13.4001C3.89602 13.6013 4.30896 13.7976 5.0158 13.9407C5.72043 14.0833 6.68378 14.1665 7.99993 14.1665C9.87935 14.1665 11.0286 13.9964 11.7306 13.7351C12.398 13.4867 12.6239 13.1728 12.7285 12.847L13.6806 13.1528C13.468 13.8149 12.9872 14.3344 12.0794 14.6723C11.2063 14.9973 9.91184 15.1665 7.99993 15.1665C6.64941 15.1665 5.61276 15.0818 4.81739 14.9208C4.02423 14.7603 3.43717 14.5172 3.01753 14.1755C2.16485 13.4811 2.1658 12.509 2.16655 11.7345C2.16657 11.7117 2.16659 11.689 2.16659 11.6665C2.16659 10.5773 2.94633 9.693 3.98363 9.1095C5.03646 8.51727 6.4572 8.16665 7.99922 8.16654Z" fill="black" />
        </svg>
    )
}

export default UserApproveIcon;
