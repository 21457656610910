import request from '../../../utils/API/apiHandler';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from '../../../shared/components/ui/Notification/Notification';
import { flattenObject } from '../TicketsList/utils';
import queryString from 'query-string';

const initialState = {
	assignStatuses: [],
	assignTags: [],
	assignUsers: [],
	isLoading: false,
	error: false,
	hhauUploadLoading: false,
	inCallTickets: [],
	createTicketEntitySearchRes: null,
	createTicketSearchLoading: false,
	createTicketSearchFills: null,
	createTicketLoading: false,
	createTicketSearchKey: null,
	searchResultTickets: [],
	exportCsvLoading: false,
};

export const exportTicketsToCsv = createAsyncThunk('exportTicketsToCsv', async ({ params, query }, { getState }) => {
	const { departmentName } = params;
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	const { showBGCheck, ticketsTotalNum } = getState().allTicketsReducer;

	let queryParams = { ...activeFilter };
	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	let yearSplitKey = 'Created At';
	let countRequests = Math.ceil(ticketsTotalNum / 5000);

	const url = query === 'EMR' ? `/tickets/${departmentName}/exportEMR` : `/tickets/${departmentName}/${showBGCheck ? 'exportBG' : 'export'}`;

	if (query === 'EMR') {
		yearSplitKey = 'Creation Date';
		countRequests = 1;
	}
	if (query !== 'EMR' && !showBGCheck) {
		yearSplitKey = 'Application Date';
		// eslint-disable-next-line new-cap
		queryParams['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}
	if (query !== 'EMR' && showBGCheck) {
		yearSplitKey = 'Application Date';
		// eslint-disable-next-line new-cap
		queryParams['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	let results = [];
	for (let index = 0; index < countRequests; index++) {
		queryParams['page'] = index;
		const response = await request({
			method: 'post',
			data: queryParams,
			url,
			timeoutOverride: 60000,
		});
		results.push(...response);
	}

	return { yearSplitKey, results };
});

export const exportAllTicketsToCsv = createAsyncThunk('exportAllTicketsToCsv', async ({ params }, { getState }) => {
	const { departmentName } = params;
	const { showBGCheck, ticketsTotalNum } = getState().allTicketsReducer;
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	let queryParams = { ...activeFilter };
	if (showBGCheck) {
		queryParams.showBGCheck = showBGCheck;
	}

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	const data = queryString.stringify(flattenObject(queryParams), { arrayFormat: 'bracket' });

	const countRequests = Math.ceil(ticketsTotalNum / 10000);
	let results = [];
	for (let index = 0; index < countRequests; index++) {
		const response = await request({
			method: 'get',
			url: `/tickets/${departmentName}/exportAll/?${data ? data : ''}&page=${index}`,
			timeoutOverride: 60000,
		});
		results.push(...response);
	}

	return results;
});

export const exportStatusTicketsToCsv = createAsyncThunk('exportStatusTicketsToCsv', async ({ params }, { getState }) => {
	const { ticketsTotalNum } = getState().allTicketsReducer;
	const { departmentName, dateRange } = params;
	const start = dateRange?.from;
	const end = dateRange?.to;

	const countRequests = Math.ceil(ticketsTotalNum / 10000);
	let results = [];
	for (let index = 0; index < countRequests; index++) {
		const response = await request({
			method: 'get',
			url: `/log-history/report-by-status-start-end-date?type=${departmentName}&start=${start}&end=${end}&page=${index}`,
			timeoutOverride: 60000,
		});
		if (!response.length) break;
		results.push(...response);
	}

	return results;
});

export const exportByActivitiesTicketsToCsv = createAsyncThunk('exportByActivitiesTicketsToCsv', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { ticketsTotalNum } = getState().allTicketsReducer;
	const { departmentName } = params;
	const start = selectedFilters?.createdAt?.from;
	const end = selectedFilters?.createdAt?.to;

	const countRequests = Math.ceil(ticketsTotalNum / 10000);
	let results = [];
	for (let index = 0; index < countRequests; index++) {
		const response = await request({
			url: `/log-history/report-activities?type=${departmentName}&start=${start}&end=${end}&page=${index}`,
			method: 'get',
			timeoutOverride: 60000,
		});
		if (!response.length) break;
		results.push(...response);
	}

	return results;
});

export const exportDocumentReportToCsv = createAsyncThunk('exportDocumentReportToCsv', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	let queryParams = { ...activeFilter };

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	return await request({
		method: 'post',
		data: queryParams,
		url: `/tickets/ats/export-documents-report`,
		timeoutOverride: 60000,
	});
});

export const exportAssignedUserToCsv = createAsyncThunk('exportAssignedUserToCsv', async ({ params }, { getState }) => {
	const { departmentName } = params;
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	const { ticketsTotalNum } = getState().allTicketsReducer;
	let queryParams = { ...activeFilter };
	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	const countRequests = Math.ceil(ticketsTotalNum / 10000);
	let results = [];
	for (let index = 0; index < countRequests; index++) {
		const response = await request({
			method: 'post',
			data: { ...queryParams, page: index },
			url: `/tickets/${departmentName}/export-assigned-user`,
			timeoutOverride: 60000,
		});
		if (!response.length) break;
		results.push(...response);
	}

	return results;
});

export const exportApprovalReport = createAsyncThunk('exportApprovalReport', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	let queryParams = { ...activeFilter };

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	return await request({
		method: 'post',
		data: queryParams,
		url: `/tickets/ats/approval-report`,
		timeoutOverride: 60000,
	});
});

export const exportPayRateReport = createAsyncThunk('exportPayRateReport', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	let queryParams = { ...activeFilter };

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	return await request({
		method: 'post',
		data: queryParams,
		url: `/tickets/ats/export-pay-rate`,
		timeoutOverride: 60000,
	});
});

export const employmentVerificationReport = createAsyncThunk('employmentVerificationReport', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	// eslint-disable-next-line new-cap
	let queryParams = { ...activeFilter, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	return await request({
		method: 'post',
		data: queryParams,
		url: `/tickets/ats/employment-verification-report`,
		timeoutOverride: 60000,
	});
});

export const newUserReport = createAsyncThunk('newUserReport', async ({ params }, { getState }) => {
	const { dateRange } = params;
	const start = dateRange?.from;
	const end = dateRange?.to;

	return await request({
		method: 'get',
		url: `/tickets/hhau/new-user-hhau-report?&start=${start}&end=${end}`,
		timeoutOverride: 60000,
	});
});

export const certificationReport = createAsyncThunk('certificationReport', async ({ params }, { getState }) => {
	const { selectedFilters } = getState().departmentFilters;
	const { activeFilter } = getState().departmentHeader;
	// eslint-disable-next-line new-cap
	let queryParams = { ...activeFilter, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };

	if (Object.keys(selectedFilters).length) {
		queryParams = {
			...queryParams,
			...selectedFilters,
		};
	}

	return await request({
		method: 'post',
		data: queryParams,
		url: `/tickets/ats/export-certification-report`,
		timeoutOverride: 60000,
	});
});

export const uploadHhauCsv = createAsyncThunk('hhau/upload-csv', async (payload, thunkAPI) => {
	return await request({ url: '/tickets/hhau/upload', data: payload, api: 'uploadMainAPI' });
});

export const getAssignables = createAsyncThunk('assignables/get', async ({ params }, thunkAPI) => {
	const { departmentName } = params;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	return await request({ url: `/${param}/${departmentName}/meta/assignables`, notify: true });
});

export const recruitmentSelectOptions = async ({ params }) => {
	const { departmentName } = params;
	return await request({ url: `/tickets/${departmentName}/#meta-info`, notify: true });
};

export const assign = createAsyncThunk('tickets/patch', async ({ params, data, operation }, thunkAPI) => {
	const { departmentName } = params;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	let resp = await request({
		method: 'patch',
		url: `/${param}/${departmentName}`,
		data: {
			operation: operation,
			ids: thunkAPI.getState().allTicketsReducer.selectedTickets,
			data: { ...data },
		},
	});
	return resp;
});

export const assignToUser = createAsyncThunk('tickets/patch', async ({ params, data, operation }, thunkAPI) => {
	const { departmentName } = params;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	let resp = await request({
		method: 'patch',
		url: `/${param}/${departmentName}/${thunkAPI.getState().allTicketsReducer.selectedTickets[0]}`,
		data: {
			operation: operation,
			// id: thunkAPI.getState().allTicketsReducer.selectedTickets[0],
			data: { ...data },
		},
	});
	return resp;
});

export const createTicketEntitySearch = createAsyncThunk('create-ticket/entity-search', async ({ params, query, entities }, thunkAPI) => {
	return await request({
		method: 'post',
		url: `/entities/${entities[0]}/search`,
		data: { query, entities },
	});
});

export const createTicket = createAsyncThunk('create-ticket/new', async ({ params, data }, thunkAPI) => {
	const { departmentName } = params;
	return await request({
		method: 'post',
		url: `/tickets/${departmentName}`,
		data: data,
		zeroState: {},
	});
});

const functionalities = createSlice({
	name: 'functionalities',
	initialState,
	reducers: {
		setCreateTicketSearchFills: (state, { payload }) => {
			state.createTicketSearchFills = payload;
		},
		createTicketReset: state => {
			state.createTicketEntitySearchRes = undefined;
			state.searchResultTickets = [];
		},
		setCreateTicketSearchKey: (state, { payload }) => {
			state.createTicketSearchKey = payload;
		},
		selectSearchTicket: (state, { payload }) => {
			state.createTicketEntitySearchRes = state.searchResultTickets.find(srt => srt._id === payload._id);
		},
	},
	extraReducers: {
		[getAssignables.rejected]: (state, { error }) => {
			state.isLoading = false;
			state.error = error;
		},
		[getAssignables.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.isLoading = false;
			state.assignUsers = payload?.users;
			state.assignTags = payload?.tags;
			state.assignStatuses = payload?.statuses;
		},
		[getAssignables.pending]: (state, action) => {
			state.isLoading = true;
		},
		[uploadHhauCsv.pending]: (state, action) => {
			state.hhauUploadLoading = true;
		},
		[uploadHhauCsv.rejected]: (state, action) => {
			state.hhauUploadLoading = false;
		},
		[uploadHhauCsv.fulfilled]: (state, action) => {
			state.error = false;
			state.hhauUploadLoading = false;
			notification('success', 'Success', 'File successfully processed');
		},
		[createTicketEntitySearch.pending]: (state, action) => {
			state.createTicketSearchLoading = true;
		},
		[createTicketEntitySearch.rejected]: (state, action) => {
			state.createTicketSearchLoading = false;
			state.createTicketEntitySearchRes = undefined;
			state.searchResultTickets = [];
			notification('info', 'Search Info', 'Entity not found');
		},
		[createTicketEntitySearch.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.createTicketSearchLoading = false;
			state.createTicketEntitySearchRes = Array.isArray(payload) ? payload[0] : payload;
			state.searchResultTickets = payload;
		},
		[createTicket.pending]: (state, action) => {
			state.createTicketLoading = true;
		},
		[createTicket.rejected]: (state, action) => {
			state.createTicketLoading = false;
		},
		[createTicket.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.createTicketLoading = false;
			notification('success', 'Success', 'New ticket successfully created');
		},
		[exportTicketsToCsv.rejected]: (state, { error }) => {
			state.exportCsvLoading = false;
			state.error = error;
			notification('error', 'Error', 'Failed to export tickets');
		},
		[exportTicketsToCsv.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.exportCsvLoading = false;
			notification('success', 'Success', 'Tickets successfully exported');
		},
		[exportTicketsToCsv.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportAllTicketsToCsv.rejected]: (state, { error }) => {
			notification('error', 'Error', 'Failed to export tickets');
		},
		[exportAllTicketsToCsv.fulfilled]: (state, { payload }) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportAllTicketsToCsv.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportStatusTicketsToCsv.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportStatusTicketsToCsv.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportByActivitiesTicketsToCsv.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportByActivitiesTicketsToCsv.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportDocumentReportToCsv.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportDocumentReportToCsv.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportApprovalReport.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportApprovalReport.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[exportPayRateReport.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[exportPayRateReport.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[employmentVerificationReport.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[employmentVerificationReport.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
		[newUserReport.fulfilled]: (state, action) => {
			notification('success', 'Success', 'Tickets successfully exported');
			state.exportCsvLoading = false;
		},
		[newUserReport.pending]: (state, action) => {
			state.exportCsvLoading = true;
		},
	},
});

export const { reducer, actions, state } = functionalities;
