import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { flattenObject } from '../../pages/Department/TicketsList/utils';

import request from '../../utils/API/apiHandler';

const initialState = {
	loading: false,
	error: false,
	messages: [],
};

export const sendMessage = createAsyncThunk('communications/post', async data => {
	return await request({ url: `messages/send`, api: 'commsAPI', method: 'post', data, notify: true });
});

export const getMessages = createAsyncThunk('communication/get', async data => {
	data = queryString.stringify(flattenObject(data), { arrayFormat: 'bracket' });
	return await request({ url: `/comms/?${data}`, api: 'commsAPI' });
});

const communications = createSlice({
	name: 'communications',
	initialState,
	reducers: {
		addMessage: (state, action) => {
			state.messages.unshift(action.payload);
		},
	},
	extraReducers: {
		[getMessages.rejected]: (state, { err }) => {
			state.loading = false;
			state.error = err;
		},
		[getMessages.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.messages = payload;
		},
		[getMessages.pending]: state => {
			state.loading = true;
			state.messages = [];
		},
	},
});
export const { reducer, reducers, actions, state } = communications;
export const { addMessage } = communications.actions;
