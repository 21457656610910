/* eslint-disable */
import BandwidthRtc from '@bandwidth/webrtc-browser';
import request from './API/apiHandler';
import { store } from '../redux/store';
import { actions as callActions } from '../features/communication/call/callSlice';
import { APP_ENV } from '../config';
import { callStatus } from '../features/communication/call/call';
import { addSocketListener } from '../utils/socketHandler';

class Bandwidth {
	constructor() {
		this._client = new BandwidthRtc(APP_ENV !== 'production' ? 'debug' : undefined);
	}

	getToken(departmentName) {
		return request({
			api: 'commsAPI',
			url: `/calls/${departmentName}/token`,
		});
	}

	startCall({ phone, departmentName, urlExtra }) {
		return new Promise(async resolve => {
			this._client.onStreamAvailable(rtcStream => {
				store.dispatch(callActions.setCallStatus(callStatus.ANSWERED));
				const audio = new Audio();
				audio.srcObject = rtcStream.mediaStream;
				audio.play();
			});

			this._client.onStreamUnavailable(endpointId => {
				resolve();
			});

			try {
				const { token } = await this.getToken(departmentName);

				await this._client.connect({ deviceToken: token });

				await this._client.publish({
					audio: true,
					video: false,
				});

				await request({
					api: 'commsAPI',
					url: `calls/${departmentName}/call-phone/en${urlExtra ? '/' + urlExtra : ''}?to=${phone}`,
				});

				store.dispatch(callActions.setCallStatus(callStatus.CALLING));

				addSocketListener('call:disconnect', async event => {
					console.log(event);
					await this.hangUpCall();
					switch (event) {
						case 'timeout':
							return resolve({ type: 'info', info: 'Call finished unanswered' });
						case 'busy':
							return resolve({ type: 'info', status: 'BUSY', info: 'Currently busy' });
						case 'rejected':
							return resolve({ type: 'info', info: 'Call rejected' });
						case 'hangup':
							return resolve();
						default:
							resolve({ type: 'error', error: new Error('Call failed') });
					}
				});
			} catch (error) {
				console.log(error);
				resolve({ type: 'error', error: new Error('Call failed') });
			}
		});
	}

	async hangUpCall() {
		this._client.disconnect();
	}

	async muteMe(departmentName, isMuted) {
		// delegate attribute means there is active connection
		// otherwise setMicEnabled throws an error
		if (this._client.delegate && typeof isMuted === 'boolean') this._client.setMicEnabled(!isMuted);
	}

	setBulkCallGenerator(payload) {
		this._bulkCallGenerator = payload;
	}

	getBulkCallGenerator() {
		return this._bulkCallGenerator;
	}

	removeBulkCallGenerator() {
		this._bulkCallGenerator = undefined;
	}
}

const bandwidth = new Bandwidth();

export default bandwidth;
