export const LAYOUT_STYLE = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};
export const STATUS_PROPS = {
    404: {
        status: 404,
        subTitle: "Not found",
        title: 404
    },
    500: {
        status: 500,
        title: 'An error has occurred',
        subTitle: "Sorry, something went wrong, we are working on a solution.",
    }
};
