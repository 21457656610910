import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { Input, Tooltip, Switch, Radio } from 'antd';
import { SendOutlined, MessageTwoTone, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import CopilotProgress from './copilotProgress';
import { ReactComponent as Icon } from '../../assets/images/icons/chat.svg';
import { copilot } from './copilotSlice';
import ChatAssistantMessage from './chatAssistantMessage';
import './chatAssistant.less';

export default function ChatAssistant({ opened, closeAction, forceTags, onSend }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [message, setMessage] = useState('');
	const [latestModel, setLatestModel] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [optionHovered, setOptionHovered] = useState(false);
	const location = useLocation();
	const chatBodyRef = useRef(null);
	const inputRef = useRef(null);
	const [department, setDepartment] = useState(null);
	const { activeTab } = useSelector(state => state.navigationReducer);
	const { messages, waitingForMessage, conversationId, type } = useSelector(state => state.copilot);

	useEffect(scrollEffect, [messages, opened, waitingForMessage]);

	useEffect(departmentEffect, [location.pathname, activeTab, opened]);

	useEffect(initialEffect, []);

	return (
		<div className={`chat-container ${opened ? '' : 'closed'}`}>
			{opened && (
				<>
					<div className="chat-header">
						<div
							className={`chat-options ${optionHovered ? 'hover' : ''}`}
							onMouseEnter={e => setOptionHovered(true)}
							onMouseLeave={e => setOptionHovered(false)}
						>
							<Tooltip title="Close chat" placement="bottomRight" arrow={{ pointAtCenter: true }}>
								<CloseOutlined onClick={closeAction} />
							</Tooltip>
							<Tooltip title="Clean history" placement="bottomRight" arrow={{ pointAtCenter: true }}>
								<DeleteOutlined onClick={cleanHistory} />
							</Tooltip>
							<Tooltip title={`Using GPT ${latestModel ? '4' : '3.5'} in next message.`} placement="bottomRight" arrow={{ pointAtCenter: true }}>
								<Switch checkedChildren="4" unCheckedChildren="3.5" onChange={checked => setLatestModel(checked)} />
							</Tooltip>
						</div>
						<div className="chat-title">
							{true && ( //!(messages.length < 2 && (!messages[0]?.content || messages[0]?.content?.list)) && (
								<span>
									<Icon className="hide-on-flouting" onClick={() => history.push('/')} />
									Welcome to our {type} Assistant!
								</span>
							)}
							{false && ( //messages.length < 2 && (!messages[0]?.content || messages[0]?.content?.list) && (
								<Radio.Group
									className="hide-on-flouting"
									options={[
										{ label: 'Copilot', value: 'copilot' },
										{ label: 'Chat', value: 'chat' },
									]}
									onChange={onTypeChange}
									value={type}
									optionType="button"
									buttonStyle="solid"
								/>
							)}
						</div>
						{1 === 2 && <div className="chat-notch">Notch example</div>}
						<div className="chat-progress">
							<CopilotProgress />
						</div>
					</div>
					<div className="chat-body" ref={chatBodyRef}>
						{messages.map((msg, i) => (
							<ChatAssistantMessage key={i} message={msg} department={department} latestModel={latestModel} />
						))}
					</div>
					<div className="chat-footer">
						<MessageTwoTone spin className={waitingForMessage ? 'loading' : ''} />
						<Input.TextArea
							ref={inputRef}
							value={message}
							disabled={waitingForMessage || messages?.some?.(msg => msg?.status === 'loading')}
							onChange={handleInputChange}
							onKeyDown={e => handleKeyDown(e, latestModel)}
							maxLength={150}
							placeholder="Type"
							autoSize={{ maxRows: 4, minRows: 1 }}
						/>
						<SendOutlined onClick={e => send(e, latestModel)} />
					</div>
				</>
			)}
		</div>
	);

	function initialEffect() {
		if (initialized) {
			return;
		}
		setInitialized(true);
		initListeners();
	}

	function scrollEffect() {
		setTimeout(() => {
			inputRef?.current?.focus?.();
			chatBodyRef?.current?.lastChild?.scrollIntoView?.({ behavior: 'smooth' });
		}, 100);
	}

	function departmentEffect() {
		if (!opened) {
			return;
		}

		const { departmentName } = getCurrentParams();
		setDepartment(departmentName);
		const info = `${departmentName?.toUpperCase?.() + (activeTab ? `/${activeTab}` : '')}`;

		if (!departmentName || messages?.findLast?.(m => m?.role === 'info')?.content === info) {
			return;
		}

		if (!ChatAssistant.allowedDepartments.includes(departmentName)) {
			return;
		}

		const messageObject = { content: info, role: 'info', conversationId };
		dispatch(copilot.addMessage(messageObject));
		requestPromptsMenu();
	}

	function handleInputChange(e) {
		setMessage(e.target.value);
	}

	function handleKeyDown(e, useLatestModel) {
		if (e?.key !== 'Enter' || e?.shiftKey) {
			return;
		}
		send(e, useLatestModel);
	}

	function send(event, useLatestModel) {
		event?.preventDefault?.();
		const msg = message.trim();
		setMessage('');
		if (!msg) {
			return;
		}
		const messageObject = { content: msg, role: 'user', status: 'sent', id: uuid(), createdAt: Date.now(), conversationId };
		dispatch(copilot.addMessage(messageObject));
		const tags = [department, activeTab, ...(Array.isArray(forceTags) ? forceTags : [])].filter(value => !!value);
		dispatch(copilot.chat({ message: messageObject, tags, latestModel: useLatestModel }));
		onSend?.(messageObject);
	}

	function getCurrentParams() {
		const availableRoutes = [
			'/management/:managementPath',
			'/:departmentName/ticket/:id/certificate',
			'/:departmentName/ticket/:id/training/:fileName',
			'/:departmentName/ticket/:id',
			'/:departmentName',
		];

		const params = {};

		availableRoutes.forEach(path => {
			Object.assign(params, matchPath(location.pathname, path)?.params || {});
		});

		return params;
	}

	function initListeners() {
		dispatch(copilot.setMessageListener({ event: 'history', listener: historyListener }));
		dispatch(copilot.setMessageListener({ event: 'new', listener: newMessageListener }));
		dispatch(copilot.setMessageListener({ event: 'status', listener: messageStatusListener }));
		dispatch(copilot.requestHistory());
	}

	function historyListener(history) {
		if (!Array.isArray(history)) {
			return;
		}
		dispatch(copilot.setMessages(history));
	}

	function newMessageListener(message, completion) {
		dispatch(copilot.addMessage({ ...message, createdAt: Date.now() }));
		dispatch(copilot.setLog({ id: message.id, log: { completion } }));
	}

	function messageStatusListener(message) {
		dispatch(copilot.modifyMessageProperty({ id: message.id, property: 'status', value: message.status }));
	}

	function requestPromptsMenu() {
		if (!opened) {
			return;
		}

		if (messages.some(msg => msg.content?.list)) {
			return;
		}
		dispatch(copilot.requestPromptsMenu());
	}

	function cleanHistory() {
		dispatch(copilot.cleanHistory());
		dispatch(copilot.setMessages([]));
		dispatch(copilot.requestPromptsMenu());
		dispatch(copilot.requestConversations());
	}

	function onTypeChange({ target: { value } }) {
		dispatch(copilot.setType(value));
		dispatch(copilot.cleanHistory());
		dispatch(copilot.setMessages([]));
		if (value === 'copilot') {
			dispatch(copilot.requestPromptsMenu());
		}
		dispatch(copilot.requestConversations());
	}
}

ChatAssistant.allowedDepartments = ['ats'];
