import React, { useState, useEffect, useMemo } from 'react';
import request from '../../../../utils/API/apiHandler';
import { calculateDocumentsStatus } from '../../../TicketDetail/TabsAts/DocumentsTab/utils';
import { makeAnswersHash } from '../../../TicketDetail/TabsAts/utils';
import './missingDocuments.less';

export default function MissingDocumentsList({ stages }) {
	const initialDocsData = {
		missingDocsList: [],
		completedList: [],
	};
	const [selectedGroup, setSelectedGroup] = useState('missing');
	const [docsData, setDocsData] = useState(initialDocsData);

	useEffect(() => {
		loadDocsData();
	}, []);

	const loadDocsData = async () => {
		const documentsStage = stages?.find(val => val.name === 'Documents');
		const newForm =
			documentsStage?.form &&
			(await request({
				url: `forms/${documentsStage?.form}`,
			}));
		const questions = newForm?.questions || [];
		const answers = documentsStage?.answers || [];
		const answersHash = makeAnswersHash(answers);

		const documentsState = calculateDocumentsStatus(questions, answersHash);

		let missingDocsList = [];
		let completedList = [];

		Object.keys(documentsState).forEach(name => {
			if (documentsState[name]) {
				completedList.push(name);
			} else {
				missingDocsList.push(name);
			}
		});

		setDocsData({
			missingDocsList,
			completedList,
		});
	};

	const missingDocsList = useMemo(() => {
		return [...(selectedGroup === 'missing' ? docsData.missingDocsList : docsData.completedList)];
	}, [docsData, selectedGroup]);

	return (
		<div className="info-modal-list">
			<div className="info-modal-list__types">
				<div
					className={selectedGroup === 'missing' ? 'active-type' : 'inactive-type'}
					onClick={() => {
						setSelectedGroup('missing');
					}}
				>
					Missing Documents <span>{docsData?.missingDocsList.length}</span>
				</div>
				<div
					className={selectedGroup === 'completed' ? 'active-type' : 'inactive-type'}
					onClick={() => {
						setSelectedGroup('completed');
					}}
				>
					Completed Documents <span>{docsData?.completedList.length}</span>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th>Document Name</th>
					</tr>
				</thead>
				<tbody>
					{missingDocsList.length ? (
						missingDocsList.map((docName, i) => {
							return (
								<tr key={i}>
									<td>{docName}</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={2}>No documents found</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
