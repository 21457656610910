import React from 'react';
import { notification } from 'antd';

export const handleUnauthenticated = err => {
	if (err.response?.status === 401) {
		if (window.location.pathname === '/login' && err.response.config.url === 'users/login') {
			if (err.response.data.message) {
				notification.error({ message: <div dangerouslySetInnerHTML={{ __html: err.response.data.message }}></div> });
			} else {
				notification.error({ message: 'Invalid email or password' });
			}
		} else if (window.location !== '/login') {
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			sessionStorage.setItem('loginError', 'Session expired');
			window.location = '/login';
			return;
		}
	}
	return Promise.reject(err);
};
