import React from 'react'

export const SearchIcon = ({ color = '#B3B3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.66668 1.83334C4.44502 1.83334 1.83334 4.44502 1.83334 7.66668C1.83334 10.8883 4.44502 13.5 7.66668 13.5C10.8883 13.5 13.5 10.8883 13.5 7.66668C13.5 4.44502 10.8883 1.83334 7.66668 1.83334ZM0.833344 7.66668C0.833344 3.89273 3.89273 0.833344 7.66668 0.833344C11.4406 0.833344 14.5 3.89273 14.5 7.66668C14.5 11.4406 11.4406 14.5 7.66668 14.5C3.89273 14.5 0.833344 11.4406 0.833344 7.66668Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.9798 12.9798C13.1751 12.7845 13.4916 12.7845 13.6869 12.9798L15.0202 14.3131C15.2155 14.5084 15.2155 14.825 15.0202 15.0202C14.825 15.2155 14.5084 15.2155 14.3131 15.0202L12.9798 13.6869C12.7845 13.4916 12.7845 13.1751 12.9798 12.9798Z" fill={color} />
        </svg>
    )
}

export default SearchIcon;
