import React from 'react'

export const DenyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99998 0.833344C4.04198 0.833344 0.833313 4.04201 0.833313 8.00001C0.833313 11.958 4.04198 15.1667 7.99998 15.1667C11.958 15.1667 15.1666 11.958 15.1666 8.00001C15.1666 4.04201 11.958 0.833344 7.99998 0.833344ZM1.83331 8.00001C1.83331 6.36451 2.48301 4.79599 3.63949 3.63952C4.79596 2.48304 6.36448 1.83334 7.99998 1.83334C9.52265 1.83334 10.9173 2.38534 11.9933 3.30001C11.9888 3.30437 11.9843 3.30881 11.98 3.31334L3.31331 11.98C3.3088 11.9844 3.30435 11.9888 3.29998 11.9933C2.351 10.8794 1.83089 9.46334 1.83331 8.00001ZM4.00665 12.7C5.18682 13.7057 6.70255 14.2299 8.25189 14.1682C9.80122 14.1064 11.2704 13.4633 12.3668 12.3669C13.4633 11.2705 14.1064 9.80125 14.1681 8.25192C14.2299 6.70258 13.7057 5.18685 12.7 4.00668C12.6958 4.0112 12.6916 4.01565 12.6873 4.02001L4.02065 12.6867C4.01627 12.6908 4.01183 12.6948 4.00731 12.6987L4.00665 12.7Z" fill="#0D0D0D" />
        </svg>
    )
}

export default DenyIcon;
