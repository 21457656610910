import React, { useEffect, useState } from 'react';
import './copilotAction.less';
import { Progress } from 'antd';

export default function CopilotAction({ action }) {
	const [progress, setProgress] = useState(0);
	useEffect(actionEffect, [action]);

	return (
		<div className="copilot-action-container">
			<Progress percent={progress} status={`${progress !== 100 ? 'active' : 'success'}`} showInfo={false} />
			<p>{action?.title || action?.description || action?.function}</p>
		</div>
	);

	function actionEffect() {
		const interval = animateProgress(action);
		return () => clearInterval(interval);
	}

	function animateProgress(data) {
		if (data?.status === 'finished') {
			setProgress(100);
			return;
		}
		let current = 0;
		const action = { type: 'increase', progress: 75, elapsed: 10, startedAt: data?.startedAt || Date.now() };
		const interval = setInterval(() => {
			if (current >= 100) {
				clearInterval(interval);
				return;
			}
			const now = Date.now();
			const elapsed = (now - action.startedAt) / 1000;
			const lastElapsed = !action.last?.startedAt ? 0 : (now - action.last?.startedAt) / 1000;
			if (action.type === 'increase' && canIncrease(action, { progress: current, elapsed, lastElapsed })) {
				current = action.progress * ((lastElapsed || elapsed) / action.elapsed);
				setProgress(current);
				return;
			}
			if (action.type === 'decrease' && canDecrease(action, { progress: current, elapsed: lastElapsed || elapsed })) {
				current = action.last.progress - (((lastElapsed || elapsed) - action.last.elapsed) / action.elapsed) * (action.last.progress - action.progress);
				setProgress(current);
				return;
			}
			generateRandomAction(action, current);
		}, 100);
		return interval;
	}

	function canIncrease(action, current) {
		return (current.lastElapsed || current.elapsed) < action.elapsed && current.progress < action.progress;
	}

	function canDecrease(action, current) {
		return current.elapsed < action.last.elapsed + action.elapsed && current.progress > action.progress;
	}

	function generateRandomAction(action, current) {
		if (action.type === 'decrease') {
			const newAction = { type: 'increase', progress: 75 + randomValue(0, 10), elapsed: 10 + randomValue(0, 20) };
			Object.assign(action, {
				...newAction,
				last: { ...action, startedAt: Date.now() - 1000 * ((current * newAction.elapsed) / newAction.progress), last: undefined },
			});
			return;
		}
		Object.assign(action, {
			type: 'decrease',
			progress: 70 - randomValue(0, 10),
			elapsed: 20 + randomValue(0, 30),
			last: { ...action, startedAt: Date.now() - 1000 * action.elapsed, last: undefined },
		});
	}

	function randomValue(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
}
