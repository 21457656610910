import React from 'react';
import {Spin} from 'antd';

export default function SuspenseLoader() {
	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<Spin size="large" />
			<p>Loading...</p>
		</div>
	);
}
