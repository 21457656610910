import * as React from 'react';
const Zoom = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none" {...props}>
		<g fill={props.fill || '#5995FE'} clipPath="url(#a)">
			<path d="M10.17 17.998c-1.54 0-3.082.001-4.623-.001-.2 0-.405 0-.602-.033a3.609 3.609 0 0 1-1.211-.423c-.845-.485-1.388-1.195-1.634-2.114a2.875 2.875 0 0 1-.095-.726c-.007-2.13-.005-4.26-.003-6.39 0-.613.55-1.214 1.168-1.28a4.5 4.5 0 0 1 .48-.029c3.098-.001 6.195 0 9.292-.002.605 0 1.175.12 1.706.407.728.393 1.256.959 1.576 1.711.22.516.26 1.056.258 1.604-.003 1.954-.007 3.91-.007 5.864 0 .26-.046.505-.189.726-.294.453-.714.688-1.272.687-1.614-.002-3.229 0-4.844 0ZM21.998 12.51c0 1.595-.005 3.189.003 4.783.002.374-.292.648-.64.7a.647.647 0 0 1-.538-.168c-1.038-.869-2.077-1.735-3.116-2.604-.26-.217-.394-.499-.395-.828-.006-1.165-.008-2.33 0-3.496.002-.303.13-.566.36-.78.952-.881 1.902-1.765 2.852-2.649.109-.1.216-.204.329-.3.219-.188.469-.21.726-.098.265.114.415.322.416.604.006 1.072.003 2.145.004 3.217v1.62h-.002Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M2 7h20v11H2z" />
			</clipPath>
		</defs>
	</svg>
);
export default Zoom;
