import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../utils/API/apiHandler';

const getAllDepartmentsStats = createAsyncThunk('departments/stats', async (payload, { getState }) => {
	const user = getState().auth.user;
	const departments = user.departments.filter(val => {
		return val !== 'Caregiver' && val !== 'Patient';
	});

	const formatDeptName = str => str.toLowerCase().split(' ').join('-');

	const departmentOptions = departments.map(department => {
		const departmentName = formatDeptName(department);
		return {
			url: `/tickets/${departmentName}/meta/headers`,
			notify: true,
			fallbackValue: [],
		};
	});

	let stats = await Promise.all(departmentOptions.map(options => request(options)));

	return stats.map((stats, index) => ({
		name: formatDeptName(departments[index]),
		stats: Object.entries(stats),
	}));
});

const initialState = {
	loading: false,
	error: false,
	stats: [],
};

const allDepartmentsStats = createSlice({
	name: 'departmentsStats',
	initialState,
	extraReducers: {
		[getAllDepartmentsStats.rejected]: (state, { error }) => {
			state.error = error;
			state.loading = false;
		},
		[getAllDepartmentsStats.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.stats = payload;
		},
		[getAllDepartmentsStats.pending]: (state, action) => {
			state.error = false;
			state.loading = true;
		},
	},
});

const { reducer, actions } = allDepartmentsStats;

export { reducer, actions, getAllDepartmentsStats };
