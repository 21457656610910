import React from 'react';

export default function IPLocation({ color = 'currentColor', size = 16, ...props }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...props}>
			<path
				fill={color}
				d="M10 18c.87 0 1.71-.14 2.494-.396a8 8 0 0 1-.974-1.63C11.024 16.675 10.486 17 10 17c-.657 0-1.407-.59-2.022-1.908A9.3 9.3 0 0 1 7.42 13.5h3.584q.019-.514.137-1H7.206A15 15 0 0 1 7 10c0-.883.073-1.725.206-2.5h5.588c.12.704.192 1.463.204 2.258q.453-.31.986-.5a16 16 0 0 0-.177-1.758h2.733c.21.549.353 1.131.419 1.736q.562.193 1.037.517A8 8 0 1 0 10 18m0-15c.657 0 1.407.59 2.022 1.908c.217.466.406 1.002.559 1.592H7.419c.153-.59.342-1.126.56-1.592C8.592 3.59 9.342 3 10 3M7.072 4.485A10.5 10.5 0 0 0 6.389 6.5H3.936a7.02 7.02 0 0 1 3.778-3.118c-.241.33-.456.704-.642 1.103M6.192 7.5A16 16 0 0 0 6 10c0 .87.067 1.712.193 2.5H3.46A7 7 0 0 1 3 10c0-.88.163-1.724.46-2.5zm.197 6c.176.743.407 1.422.683 2.015c.186.399.401.773.642 1.103A7.02 7.02 0 0 1 3.936 13.5zm5.897-10.118A7.02 7.02 0 0 1 16.064 6.5H13.61a10.5 10.5 0 0 0-.683-2.015a6.6 6.6 0 0 0-.642-1.103M19 13.682c0-2.033-1.465-3.681-3.499-3.681S12 11.649 12 13.682c0 1.524.982 3.53 3.256 5.236c.145.11.345.11.49 0C18.022 17.212 19 15.206 19 13.682m-2-.182a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0"></path>
		</svg>
	);
}
