import request from '../../utils/API/apiHandler';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from '../../shared/components/ui/Notification/Notification';

const initialState = {
	loading: false,
};

export const sendEmailForPassChange = createAsyncThunk('forgotPassword/sendEmail', async (payload, thunkAPI) => {
	return request({
		url: 'users/password-change/request',
		data: { email: payload },
		method: 'post',
		notify: true,
		fallbackData: Promise.reject(),
	});
});

export const sendPasswordChange = createAsyncThunk('forgotPassword/changePassword', async (payload, thunkAPI) => {
	return request({
		url: `users/password-change/${payload.token}`,
		data: { password: payload.password },
		method: 'post',
		notify: true,
		fallbackData: Promise.reject(),
	});
});

const forgotPasswordSlice = createSlice({
	name: 'forgotPassword',
	initialState,
	reducers: {},
	extraReducers: {
		[sendEmailForPassChange.rejected]: (state, action) => {
			state.loading = false;
		},
		[sendEmailForPassChange.pending]: (state, action) => {
			state.loading = true;
		},
		[sendEmailForPassChange.fulfilled]: (state, { payload }) => {
			state.loading = false;
			notification('success', 'Success', payload.message);
		},
		[sendPasswordChange.rejected]: (state, action) => {
			state.loading = false;
		},
		[sendPasswordChange.pending]: (state, action) => {
			state.loading = true;
		},
		[sendPasswordChange.fulfilled]: (state, { payload }) => {
			state.loading = false;
			notification('success', 'Success', payload);
		},
	},
});

export const { reducer, actions } = forgotPasswordSlice;
