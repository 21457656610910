import React from 'react';

const StatusSuccessIcon = ({ color = '#31A619', size = 16 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
			<g id="Group">
				<path
					id="Vector"
					d="M10.6862 6.68612C10.7354 6.64034 10.7748 6.58514 10.8021 6.52381C10.8294 6.46248 10.8441 6.39627 10.8453 6.32913C10.8465 6.262 10.8341 6.19531 10.809 6.13305C10.7838 6.07079 10.7464 6.01424 10.6989 5.96676C10.6515 5.91928 10.5949 5.88185 10.5326 5.8567C10.4704 5.83156 10.4037 5.81921 10.3366 5.82039C10.2694 5.82157 10.2032 5.83627 10.1419 5.8636C10.0805 5.89093 10.0253 5.93033 9.97957 5.97945L6.99957 8.95945L6.01957 7.97945C5.92479 7.89113 5.79942 7.84305 5.66989 7.84533C5.54036 7.84762 5.41677 7.9001 5.32516 7.9917C5.23355 8.08331 5.18107 8.2069 5.17879 8.33644C5.1765 8.46597 5.22459 8.59133 5.31291 8.68612L6.64624 10.0195C6.73999 10.1131 6.86707 10.1657 6.99957 10.1657C7.13207 10.1657 7.25916 10.1131 7.35291 10.0195L10.6862 6.68612Z"
					fill={color}
				/>
				<path
					id="Vector_2"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.99967 0.833008C4.04167 0.833008 0.833008 4.04167 0.833008 7.99967C0.833008 11.9577 4.04167 15.1663 7.99967 15.1663C11.9577 15.1663 15.1663 11.9577 15.1663 7.99967C15.1663 4.04167 11.9577 0.833008 7.99967 0.833008ZM1.83301 7.99967C1.83301 6.36417 2.48271 4.79566 3.63918 3.63918C4.79566 2.48271 6.36417 1.83301 7.99967 1.83301C9.63518 1.83301 11.2037 2.48271 12.3602 3.63918C13.5166 4.79566 14.1663 6.36417 14.1663 7.99967C14.1663 9.63518 13.5166 11.2037 12.3602 12.3602C11.2037 13.5166 9.63518 14.1663 7.99967 14.1663C6.36417 14.1663 4.79566 13.5166 3.63918 12.3602C2.48271 11.2037 1.83301 9.63518 1.83301 7.99967Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default StatusSuccessIcon;
