import {createSlice} from '@reduxjs/toolkit';
import {callStatus} from './call';

const initialState = {
	callInfo: { title: '', phone: '' },
	loading: false,
	isInCall: false,
	callStatus: callStatus.LOADING,
	callWidgetPosition: { top: '50px', left: document.body.offsetWidth - 370 + 'px' },
	ticketsToCall: [],
	coordinationActiveTab: '',
	coordinationTableInfo: {
		current: 1,
		pageSize: 10,
	},
	isSingleCall: false
};

const callSlice = createSlice({
	name: 'callInfo',
	initialState,
	reducers: {
		setSingleCall: (state, { payload }) => {
			state.isSingleCall = payload;
		},
		setCallWidgetPosition: (state, { payload }) => {
			state.callWidgetPosition = payload;
		},
		setCallStatus: (state, { payload }) => {
			state.callStatus = payload;
		},
		setIsInCall: (state, { payload }) => {
			state.isInCall = payload;
		},
		setTicketsToCall: (state, { payload }) => {
			state.ticketsToCall = payload;
		},
		setCallInfo: (state, { payload }) => {
			state.callInfo = payload;
		},
		setCoordinationActiveTab: (state, { payload }) => {
			state.coordinationActiveTab = payload;
		},
		setCoordinationTableInfo: (state, { payload }) => {
			state.coordinationTableInfo = { ...state.coordinationTableInfo, ...payload };
		},
	},
});

export const { actions, reducer } = callSlice;
