import axios from 'axios';
import get from 'lodash/get';

export const getProp = (obj, prop) => {
	return get(obj, prop, undefined);
};

export function generateHexColor(userId) {
	return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
}

export const formatPhoneNumber = phoneNumberString => {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return `(${match[2]}) ${match[3]}-${match[4]}`;
	}

	return null;
};

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeEachWord = string =>
	string
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

export const isProduction = process.env.REACT_APP_ENV === 'production';

export const convertArrayToObject = (array, key) => {
	return array?.reduce((result, item) => {
		result[item[key]] = item;
		return result;
	}, {});
};

export const formatPhoneNumberForSearch = phoneNumberString => phoneNumberString.replace(/-/g, '');

export const sortArray = (array, sortColumn, sortDirection) => {
	return [...array].sort((a, b) => {
		const valueA = a[sortColumn];
		const valueB = b[sortColumn];

		const sortIsAsc = sortDirection === 'asc';

		if (valueA === undefined && valueB === undefined) {
			return 0;
		}

		if (valueA === undefined) {
			return sortIsAsc ? 1 : -1;
		}

		if (valueB === undefined) {
			return sortIsAsc ? -1 : 1;
		}

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return sortIsAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
		} else {
			return sortIsAsc ? valueA - valueB : valueB - valueA;
		}
	});
};

export function inProdHost() {
	const { hostname } = document.location;

	if (!hostname) {
		return false;
	}

	return !(hostname.includes('dev') || hostname.includes('localhost') || hostname.includes('staging'));
}

export const sendMessageToSlack = (ticketId, message, channel) => {
	return axios({
		url: `/tickets/ats/${ticketId}/sendMessageToSlack`,
		data: { message, channel },
		method: 'post',
	});
};

export const getValueFromPath = (obj, pathArray) => {
	return pathArray.reduce((acc, key) => acc?.[key], obj);
};
