import * as React from 'react';
const Filter = props => {
	const { size = 16, color = 'currentColor' } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
			<path
				fill={color}
				d="M12.833 8.167a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1 0-1h8a.5.5 0 0 1 .5.5Zm2-3.5h-13a.5.5 0 1 0 0 1h13a.5.5 0 0 0 0-1Zm-5 6h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1Z"
			/>
		</svg>
	);
};
export default Filter;
