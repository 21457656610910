import * as React from 'react';
const Hhau = props => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width={16} height={16} fill="none">
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M13.12 9.879c-.086.046-.103.101-.103.14v4.659c0 .037.017.092.102.139a.314.314 0 0 0 .296.003l4.51-2.333a.2.2 0 0 0 .08-.07.13.13 0 0 0 .021-.07.13.13 0 0 0-.021-.069.199.199 0 0 0-.08-.069l-.006-.003-4.503-2.33a.314.314 0 0 0-.297.003Zm-1.772.14c0-1.497 1.642-2.244 2.833-1.629l4.501 2.329c.298.151.553.38.734.666a1.803 1.803 0 0 1 0 1.926c-.18.286-.436.514-.734.666l-4.5 2.328c-1.192.617-2.834-.131-2.834-1.627v-4.66Z"
			clipRule="evenodd"
		/>
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M10.473 4h9.053c1.526 0 2.758 0 3.726.131 1.004.135 1.85.427 2.522 1.102.672.677.96 1.528 1.096 2.539.13.974.13 2.213.13 3.748v1.247c0 1.536 0 2.775-.13 3.749-.136 1.01-.424 1.862-1.096 2.538-.672.677-1.518.966-2.523 1.103-.967.13-2.199.13-3.725.13h-3.689v1.921l6.125 2.054a.836.836 0 0 1 .571.74.847.847 0 0 1-.46.813.835.835 0 0 1-.64.046L15 23.702 8.567 25.86a.833.833 0 0 1-1.09-.522.848.848 0 0 1 .265-.918.835.835 0 0 1 .296-.157l6.125-2.056v-1.918h-3.69c-1.525 0-2.757 0-3.725-.13-1.004-.138-1.85-.428-2.522-1.104-.672-.676-.96-1.527-1.095-2.538C3 15.542 3 14.303 3 12.767V11.52c0-1.535 0-2.774.13-3.748.135-1.011.425-1.862 1.096-2.539.672-.676 1.518-.966 2.522-1.102C7.716 4 8.948 4 10.473 4Zm-3.5 1.8c-.82.112-1.253.315-1.562.625-.31.311-.51.747-.62 1.573-.113.846-.116 1.971-.116 3.584v1.123c0 1.612.003 2.738.115 3.586.11.825.313 1.26.621 1.572.31.31.742.513 1.563.624.842.114 1.96.116 3.562.116h8.93c1.602 0 2.72-.003 3.563-.116.82-.111 1.253-.314 1.562-.624.31-.312.51-.747.62-1.573.113-.848.116-1.973.116-3.584v-1.124c0-1.612-.003-2.737-.115-3.585-.11-.825-.313-1.26-.621-1.572-.31-.311-.742-.513-1.563-.624-.843-.114-1.96-.116-3.562-.116h-8.93c-1.602 0-2.72.002-3.563.116Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default Hhau;
