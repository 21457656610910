import * as React from 'react';
const Ats = props => {
	const { fill = 'currentColor', size = 16 } = props || {};
	return (
		<svg xmlns="http://www.w3.org/2000/svg" {...props} width={size} height={size} viewBox="0 0 26 28" fill="none">
			<path
				fill={fill}
				fillRule="evenodd"
				d="M17.276 8.522H14.92c-.074.472-.2.927-.37 1.36l.774-.001h1.898c1.81 0 3.095.002 4.07.127.954.122 1.505.352 1.906.735.402.383.643.909.77 1.82.132.932.134 2.158.134 3.886v3.624c0 1.727-.002 2.955-.133 3.886-.128.91-.37 1.436-.77 1.82-.402.383-.953.613-1.906.735-.976.125-2.261.127-4.071.127h-1.898c-1.81 0-3.095-.002-4.07-.127-.955-.122-1.505-.352-1.906-.735-.402-.384-.643-.91-.771-1.82-.13-.93-.133-2.159-.133-3.886v-3.624c0-.959 0-1.764.024-2.45a6.516 6.516 0 0 1-1.418-.161c-.028.744-.028 1.594-.028 2.56v3.726c0 1.665 0 2.983.145 4.016.15 1.061.465 1.921 1.175 2.6.71.678 1.611.978 2.723 1.121 1.081.139 2.463.139 4.207.139h2.004c1.744 0 3.125 0 4.207-.139 1.112-.143 2.012-.444 2.723-1.121.71-.679 1.025-1.538 1.175-2.6.145-1.032.145-2.351.145-4.016v-3.726c0-1.665 0-2.983-.145-4.016-.15-1.062-.465-1.921-1.175-2.6-.71-.678-1.611-.978-2.723-1.122-1.081-.138-2.463-.138-4.207-.138Zm-5.301 5.635a.664.664 0 0 0-.209.48c0 .18.075.353.209.48a.73.73 0 0 0 .503.2h7.592a.73.73 0 0 0 .503-.2.664.664 0 0 0 .208-.48.664.664 0 0 0-.208-.48.729.729 0 0 0-.503-.2h-7.592a.729.729 0 0 0-.503.2Zm0 3.623a.664.664 0 0 0-.209.48c0 .181.075.354.209.481.133.128.314.2.503.2h7.592c.188 0 .37-.072.503-.2a.664.664 0 0 0 .208-.48.664.664 0 0 0-.208-.48.73.73 0 0 0-.503-.2h-7.592a.73.73 0 0 0-.503.2Zm0 3.624a.664.664 0 0 0-.209.48c0 .181.075.354.209.481a.73.73 0 0 0 .503.2h4.745a.73.73 0 0 0 .503-.2.664.664 0 0 0 .209-.48.664.664 0 0 0-.209-.48.73.73 0 0 0-.503-.2h-4.745a.73.73 0 0 0-.503.2Z"
				clipRule="evenodd"
			/>
			<path
				fill={fill}
				fillRule="evenodd"
				d="M14.083 7.207a5.746 5.746 0 1 0-11.493 0 5.746 5.746 0 0 0 11.493 0ZM8.336 0a7.207 7.207 0 1 1 0 14.414A7.207 7.207 0 0 1 8.337 0Z"
				clipRule="evenodd"
			/>
			<path
				fill={fill}
				fillRule="evenodd"
				d="M3.058 12.485a.73.73 0 0 1 0 1.033l-1.363 1.364a.73.73 0 1 1-1.033-1.033l1.363-1.364a.73.73 0 0 1 1.033 0Z"
				clipRule="evenodd"
			/>
			<path
				fill={fill}
				d="M8.384 6.84a1.52 1.52 0 1 0 0-3.042 1.52 1.52 0 0 0 0 3.042ZM8.384 11.021c1.47 0 2.662-.68 2.662-1.52S9.854 7.98 8.384 7.98s-2.66.68-2.66 1.52 1.19 1.52 2.66 1.52Z"
			/>
		</svg>
	);
};
export default Ats;
