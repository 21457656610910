import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

export const addDays = function (dateParam, days) {
	var date = new Date(dateParam);
	date.setDate(date.getDate() + days);
	return date;
};

export const subDays = function (dateParam, days) {
	var date = new Date(dateParam);
	date.setDate(date.getDate() - days);
	return date;
};

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export default dayjs;
