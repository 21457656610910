/**
 * We have communications with 3 main APIs:
 * 1. Main API(tickets) with prefix: api
 * 2. Bot API(bots) with prefix: bot
 * 3. Communication API(comm. engine) with prefix: comms
 *
 * [Frontend]
 * Production deployment: *.arla.ai
 * Stage deployment: *.staging.arla.ai
 *
 * [Backend]
 * Production deployment: *.[api/comms/bot].arla.ai
 * Stage deployment: *.[api/comms/bot].staging.arla.ai
 */

const APP_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';
const STATIC_DOMAIN_PART = APP_ENV === 'stage' ? '.staging.arla.ai' : APP_ENV === 'dev' ? '.dev.arla.ai' : '.arla.ai';
const CLIENT_NAME = APP_ENV === 'dev' ? 'dev' : getClientName();

const WEBSOCKET_NAME = APP_ENV === 'development' ? process.env.REACT_APP_WEBSOCKET_NAME : CLIENT_NAME;

function getGatewayUrl(apiPrefix) {
	return `https://${CLIENT_NAME}.${apiPrefix}${STATIC_DOMAIN_PART}`;
}

export function getClientName() {
	const clientHostname = window.location.hostname;

	return clientHostname.slice(0, -STATIC_DOMAIN_PART.length);
}

function getApiGateway(env) {
	let apiGateway;

	switch (env) {
		case 'dev':
		case 'stage':
		case 'production':
			apiGateway = {
				mainAPI: getGatewayUrl('api'),
				botAPI: getGatewayUrl('bot'),
				commsAPI: getGatewayUrl('comms'),
			};
			break;
		default:
			apiGateway = {
				// mainAPI: 'https://staging.api.staging.arla.ai',
				// botAPI: 'http://localhost:1337',
				// commsAPI: 'https://staging.comms.staging.arla.ai',
				mainAPI: 'http://localhost:1337',
				botAPI: 'http://localhost:1338',
				commsAPI: 'http://localhost:1339',
			};
	}

	return apiGateway;
}

function getSocketGateway(API_GATEWAY) {
	let SOCKET_GATEWAY = {};
	for (let key in API_GATEWAY) {
		SOCKET_GATEWAY[key] = `${API_GATEWAY[key].replace('http', 'ws')}/${WEBSOCKET_NAME}`;
	}
	return SOCKET_GATEWAY;
}

const API_GATEWAY = getApiGateway(APP_ENV);

const SOCKET_GATEWAY = getSocketGateway(API_GATEWAY);

export { STATIC_DOMAIN_PART, CLIENT_NAME, APP_ENV, API_GATEWAY, SOCKET_GATEWAY };
