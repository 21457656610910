/**
 * A wrapper for <Route> that redirects to Login screen if you are not authenticated
 */
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {notification} from "antd";

export default function AuthRoute({ children, ...rest }) {
	const isLoggedIn = useSelector(state => state.auth.loggedIn);
	if (!isLoggedIn) {
		localStorage.setItem('redirectedFromUrl', window.location.pathname);
		notification.info({message: 'Sign in to continue', key: "signInNotif"});
	}
	return (
		<Route
			{...rest}
			render={({ location }) => {
				return isLoggedIn ? (
					children
				) : (
					<Redirect to={{ pathname: '/login', state: { from: location } }} />
				);
			}}
		/>
	);
}
