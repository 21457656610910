import React from 'react';
import { Form, Input } from 'antd';

export const PhoneFormItem = ({ itemName, itemLabel, isRequired }) => {
	return (
		<Form.Item name={itemName} label={itemLabel} {...phoneFormItemProps(isRequired)}>
			<Input placeholder="Type here" prefix={'+1'} />
		</Form.Item>
	);
};

export const addPhoneDashes = phone => phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const phoneFormItemProps = requiredValue => ({
	getValueFromEvent: e => {
		const { value } = e.target;
		return `+1${value.toString().slice(0, 12).replaceAll('-', '')}`;
	},
	normalize: value => {
		return addPhoneDashes(value.slice(2));
	},
	getValueProps: value => ({ value: value && addPhoneDashes(value.replace('+1', '')) }),
	tooltip: 'Enter a US phone number in the format: +1xxx-xxx-xxxx',
	rules: [
		{
			validator: (_, value) => {
				if (!value) return Promise.resolve();
				return value.length < 10 || value.replaceAll('-', '').match(/[^0-9^+]/)
					? Promise.reject(new Error('Please enter a valid phone number'))
					: Promise.resolve();
			},
		},
		{ message: 'Please enter a valid phone number', required: requiredValue },
	],
});

export const formatTONormalPhone = text => {
	return (
		text
			// Step 1: Remove '-000' from each segment
			.replace(/-000/g, '')
			// Step 2: Split the text by '|'
			.split('|')
			// Step 3: Normalize each phone number
			.map(number => addPhoneDashes(number.replace(/^(?:\+?1)/, '')))
			// Join the normalized numbers with '-'
			.join(' - ')
	);
};
