import React from 'react';

export const SignIcon = ({ size = 16, color = 'currentColor', ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props} fill="none">
			<path
				fill={color}
				fillRule="evenodd"
				d="M9.4 1.594a2.597 2.597 0 0 1 3.673 3.673L8.01 10.33c-.285.285-.46.46-.653.611a3.994 3.994 0 0 1-.739.456c-.223.106-.457.184-.84.312l-1.78.594-.429.142a1.065 1.065 0 0 1-1.348-1.348l.737-2.209c.127-.383.205-.617.311-.84a4 4 0 0 1 .457-.737c.151-.194.325-.369.61-.654L9.4 1.594Zm-5.427 9.663-.562-.564.485-1.457c.141-.424.2-.597.276-.757.094-.196.208-.38.342-.553.11-.14.237-.27.553-.586l3.928-3.927c.204.51.51.973.9 1.36.386.39.85.695 1.36.9L7.326 9.598c-.317.316-.446.444-.586.553a3.042 3.042 0 0 1-.553.343c-.16.076-.333.134-.757.276l-1.458.486Zm8.078-6.382a3 3 0 0 1-1.45-.809 3.028 3.028 0 0 1-.81-1.45l.316-.315a1.598 1.598 0 0 1 2.258 2.26l-.314.314Zm-9.884 9.792a.5.5 0 0 1 .5-.5h10.666v1H2.667a.5.5 0 0 1-.5-.5Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default SignIcon;
