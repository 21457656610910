import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import ErrorPage from '../../../pages/ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
	state = {
		hasError: false,
		location: null,
	};
	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	static getDerivedStateFromProps(newProps, oldState) {
		const navigated = newProps.location !== oldState.location;
		if (navigated && oldState.hasError) {
			return {
				hasError: false,
				location: newProps.location,
			};
		} else {
			return {
				location: newProps.location,
			};
		}
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
		Sentry.captureException(error);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage status={500} />;
		} else {
			return this.props.children;
		}
	}
}

export default withRouter(ErrorBoundary);
