import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import request from '../../utils/API/apiHandler';
import CompanyInformation from './CompanyInformation/CompanyInformation';
import Contacts from './Contacts/Contacts';
import './AccountSettings.less';
import { useForm } from 'antd/es/form/Form';

const AccountSettings = () => {
	const [companyInfo, setCompanyInfo] = useState(false);
	const [form] = useForm();

	useEffect(() => {
		(async () => {
			const data = await request({ url: `/company-profile` });
			form.setFieldsValue(data);
			setCompanyInfo(true);
		})();
	}, []);

	if (!companyInfo) return null;

	const TABS = [
		{
			key: 'Company Information',
			label: 'Company Information',
			children: <CompanyInformation form={form} />,
		},
		{
			key: 'Contacts',
			label: 'Contacts',
			children: <Contacts form={form} />,
		},
	];

	return (
		<div className="account-settings">
			<div className="account-settings__header">
				<h3>Account Settings</h3>
				<span>Here you can edit your company account data, and manage billing details.</span>
			</div>
			<div className="account-settings__tabs">
				<Tabs items={TABS} type="card"></Tabs>
			</div>
		</div>
	);
};

export default AccountSettings;
