import socketIOClient from 'socket.io-client';
import { SOCKET_GATEWAY } from '../config';

const checkSocketConnection = () => {
	return new Promise((resolve, reject) => {
		if (socket.connected) resolve();

		const maxNumberOfAttempts = 10;
		const intervalTime = 200;

		let currentAttempt = 0;

		const interval = setInterval(() => {
			if (currentAttempt > maxNumberOfAttempts - 1) {
				clearInterval(interval);
				reject(new Error('Maximum number of attempts exceeded'));
			} else if (socket.connected === true) {
				clearInterval(interval);
				resolve();
			}
			currentAttempt++;
		}, intervalTime);
	});
};

export const addSocketListener = (event, callback) => {
	checkSocketConnection()
		.then(() => {
			socket.on(event, callback);
		})
		.catch(err => console.log(err));
};

export const socket = socketIOClient.io(SOCKET_GATEWAY.mainAPI, {
	auth: { token: localStorage.getItem('token') || null },
	transports: ['websocket'],
	reconnectionAttempts: 100,
	path: '/socket.io',
});

socket.on('connect_error', err => {
	if (['jwt must be provided', 'jwt malformed'].includes(err.message)) {
		socket.auth = { token: localStorage.getItem('token') || null };
		socket.connect();
	}
});
