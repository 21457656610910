import React from 'react';

export const StatusErrorIcon = ({ color = '#A61919', size = 16 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.64645 5.64645C5.84171 5.45118 6.15829 5.45118 6.35355 5.64645L8 7.29289L9.64645 5.64645C9.84171 5.45118 10.1583 5.45118 10.3536 5.64645C10.5488 5.84171 10.5488 6.15829 10.3536 6.35355L8.70711 8L10.3536 9.64645C10.5488 9.84171 10.5488 10.1583 10.3536 10.3536C10.1583 10.5488 9.84171 10.5488 9.64645 10.3536L8 8.70711L6.35355 10.3536C6.15829 10.5488 5.84171 10.5488 5.64645 10.3536C5.45118 10.1583 5.45118 9.84171 5.64645 9.64645L7.29289 8L5.64645 6.35355C5.45118 6.15829 5.45118 5.84171 5.64645 5.64645Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99998 0.833344C4.04198 0.833344 0.833313 4.04201 0.833313 8.00001C0.833313 11.958 4.04198 15.1667 7.99998 15.1667C11.958 15.1667 15.1666 11.958 15.1666 8.00001C15.1666 4.04201 11.958 0.833344 7.99998 0.833344ZM1.83331 8.00001C1.83331 6.36451 2.48301 4.79599 3.63949 3.63952C4.79596 2.48304 6.36448 1.83334 7.99998 1.83334C9.63548 1.83334 11.204 2.48304 12.3605 3.63952C13.5169 4.79599 14.1666 6.36451 14.1666 8.00001C14.1666 9.63551 13.5169 11.204 12.3605 12.3605C11.204 13.517 9.63548 14.1667 7.99998 14.1667C6.36448 14.1667 4.79596 13.517 3.63949 12.3605C2.48301 11.204 1.83331 9.63551 1.83331 8.00001Z"
				fill={color}
			/>
		</svg>
	);
};
