import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { socket } from '../../utils/socketHandler';

const initialState = {
	messages: [],
	actions: [],
	contexts: [],
	type: 'copilot',
	conversations: [],
	logs: getLogsInStorage(),
	debuggingId: null,
	waitingForMessage: false,
	conversationId: null,
};

const copilotSlice = createSlice({
	name: 'copilotReducer',
	initialState,
	reducers: {
		setMessages: (state, { payload }) => {
			state.messages = JSON.parse(JSON.stringify(Array.isArray(payload) ? payload : []));
		},
		setContexts: (state, { payload }) => {
			state.contexts = JSON.parse(JSON.stringify(Array.isArray(payload) ? payload : []));
		},
		setLog: ({ logs }, { payload: { id, log } }) => {
			if (!id || !log?.completion) {
				return;
			}
			logs[id] = log;
			saveLogsInStorage(logs);
		},
		debugMessage: (state, { payload }) => {
			state.debuggingId = payload;
		},
		setConversations: (state, { payload }) => {
			state.conversations = payload;
		},
		setConversationId: (state, { payload }) => {
			state.conversationId = payload;
		},
		addAction: (state, { payload }) => {
			state.actions = [...state.actions, payload];
		},
		setFinished: (state, { payload }) => {
			state.actions = state.actions.map(a => (a.function === payload.function ? { ...a, status: 'finished' } : a));
		},
		removeAction: (state, { payload }) => {
			state.actions = state.actions.filter(a => a.function !== payload.function);
		},
		addMessage: (state, { payload }) => {
			Object.assign(payload, { id: payload?.id || uuid() });
			state.messages = [...state.messages, payload];
			state.waitingForMessage = false;
			state.conversationId = payload.conversationId;
		},
		setMessageListener: (neverUseThisState, { payload }) => {
			// We strongly recommend never set the listener value in the state, it's non-serializable.
			const { event, listener } = payload;
			let eventName = event;
			if (!eventName?.startsWith?.('copilot:message:')) {
				eventName = `copilot:message:${eventName}`;
			}
			socket.off(eventName);
			socket.on(eventName, listener);
		},
		setFunctionListener: (neverUseThisState, { payload }) => {
			// We strongly recommend never set the listener value in the state, it's non-serializable.
			const { event, listener } = payload;
			let eventName = event;
			if (!eventName?.startsWith?.('copilot:function:')) {
				eventName = `copilot:function:${eventName}`;
			}
			socket.off([eventName]);
			socket.on(eventName, listener);
		},
		requestHistory: state => {
			socket.emit('service:run', 'Copilot', 'sendUserHistory', state.conversationId);
		},
		requestConversations: () => {
			socket.emit('service:run', 'Copilot', 'sendConversations');
		},
		setType: (state, { payload }) => {
			state.type = payload;
		},
		startAction: (state, { payload }) => {
			socket.emit('service:run', 'Copilot', 'startAction', payload);
		},
		removeListener: (state, { payload }) => {
			if (socket.hasListeners(payload)) {
				socket.off(payload);
			}
		},
		requestPromptsMenu: state => {
			state.waitingForMessage = true;
			socket.emit('service:run', 'Prompt', 'sendPromptsMenu');
		},
		cleanHistory: state => {
			socket.emit('service:run', 'Copilot', 'cleanHistory');
			state.conversationId = null;
		},
		chat: (state, { payload: { message, tags = [], latestModel } }) => {
			state.contexts = [];
			socket.emit('service:run', 'Copilot', 'chat', { ...message, conversationId: state.conversationId }, tags, latestModel);
		},
		modifyMessageProperty: (state, { payload }) => {
			const { id, property, value } = payload;
			const messages = [...state.messages];
			const index = messages.findIndex(m => m.id === id);
			if (index === -1) {
				return;
			}
			messages.splice(index, 1, { ...messages[index], [property]: value });
			state.messages = messages;
		},
	},
	extraReducers: {},
});

export const { reducer, state } = copilotSlice;
export const copilot = copilotSlice.actions;

function saveLogsInStorage(logs) {
	Object.keys(logs).forEach(id => {
		const item = localStorage.getItem(`copilot:logs:${id}`);
		if (!item) {
			localStorage.setItem(`copilot:logs:${id}`, JSON.stringify({ expire: Date.now() + 1000 * 60 * 10, logs: logs[id] })); // 10 minutes
		}
	});
}

function getLogsInStorage() {
	const logs = {};
	Object.keys(localStorage).forEach(key => {
		if (!key.startsWith('copilot:logs:')) {
			return;
		}
		const item = localStorage.getItem(key);
		if (!item) {
			return;
		}
		const { expire, logs: value } = JSON.parse(item);
		if (expire > Date.now()) {
			logs[key.replace('copilot:logs:', '')] = value;
		} else {
			localStorage.removeItem(key);
		}
	});
	return logs;
}
