export const ADDED = 'added';
export const EDITED = 'edited';
export const CHANGE = 'changed';
export const REMOVED = 'removed';
export const RUN = 'ran';
export const REPLACED = 'replaced';
export const SPLIT = 'split';
export const MERGED = 'merged';
export const DELETED = 'deleted';
export const CLASSIFIED = 'classified';
export const DELETED_MULTIPLE = 'deleted multiple';
export const SET_STAMPED = 'set stamped';
export const COMPLETED = 'completed';
export const LEFT_INCOMPLETE = 'left incomplete';
export const UPLOADED = 'uploaded';

export const KEY_MAP = [
	{ old: 'add', current: ADDED },
	{ old: 'edit', current: EDITED },
	{ old: 'change', current: CHANGE },
	{ old: 'remove', current: REMOVED },
	{ old: 'run', current: RUN },
	{ old: 'replace', current: REPLACED },
	{ old: 'split', current: SPLIT },
	{ old: 'merge', current: MERGED },
	{ old: 'delete', current: DELETED },
	{ old: 'classification', current: CLASSIFIED },
	{ old: 'delete multiple', current: DELETED_MULTIPLE },
	{ old: 'set stamp', current: SET_STAMPED },
	{ old: 'complete', current: COMPLETED },
	{ old: 'incomplete', current: LEFT_INCOMPLETE },
	{ old: 'upload', current: UPLOADED },
];

export const authorizedEmails = ['joes@thrivedatainc.com'];
