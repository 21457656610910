import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Logger } from '../../utils/front_logger';
const { REACT_APP_ENV, REACT_APP_LOGGER_ID } = process.env;

const useLogger = () => {
	const [logging, setLogging] = useState(false);
	const user = useSelector(state => state.auth.user);

	useEffect(() => {
		if (!logging && Boolean(user?._id)) {
			new Logger({
				env: REACT_APP_ENV,
				cognitoId: REACT_APP_LOGGER_ID,
				userId: user._id,
			});
			setLogging(true);
		}
	}, [logging, user]);
};

export default useLogger;
