import bandwidth from './utils/bandwidthSetup';

import { CLIENT_NAME } from './config';

const CLIENTS = {
	dev: bandwidth,
	aoc1: bandwidth,
	aoc: bandwidth,
	hih: bandwidth,
	quality: bandwidth,
	anchor: bandwidth,
	responsible: bandwidth,
	allhealth: bandwidth,
	magic: bandwidth,
	hcs: bandwidth,
	aj: bandwidth,
	abj: bandwidth,
	patriot: bandwidth,
	staging: bandwidth,
	demo: bandwidth,
};

export const communicationClient = CLIENTS[CLIENT_NAME] || bandwidth;
