import React, { useCallback, useEffect, useState } from 'react';
import { Button, Popover, Progress } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addSocketListener, socket } from '../../../utils/socketHandler';
import throttle from 'lodash/throttle';
import './ProgressNotification.less';
import { ProgressUpload } from '../../../assets/images/icons';

const ProgressNotification = () => {
	const location = useLocation();
	const [data, setData] = useState({});
	const { activeTab } = useSelector(state => state.navigationReducer);

	const updateData = useCallback(
		throttle(data => {
			setData(data);
		}, 750),
		[],
	);

	useEffect(() => {
		addSocketListener('update', payload => {
			updateData(payload);
		});
		return () => {
			socket.removeAllListeners('update');
		};
	}, [location]);

	useEffect(() => {
		let timer = null;
		const listenAnyMessage = (event, payload, ...other) => {
			if (event !== 'UIProgressNotification') {
				return;
			}
			if (
				!payload ||
				!payload?.title ||
				!payload?.type ||
				!payload?.moduleName ||
				!payload?.total ||
				(!payload?.remaining && payload?.remaining !== 0) ||
				!location.pathname.includes(payload?.moduleName)
			) {
				return;
			}
			console.log('listenAnyMessage', payload);
			updateData({ data: payload });
			if (payload?.remaining === 0 && payload?.avoidDismiss) {
				timer = setTimeout(() => setData(null), 10000); // remove notification after 10 seconds
			}
		};

		socket.onAny(listenAnyMessage);

		return () => {
			socket.offAny(listenAnyMessage);
			clearTimeout(timer);
		};
	}, [location.pathname, activeTab]);

	const activeDepartment = location.pathname.slice(1).includes(data?.data?.moduleName);
	const popoverContent = activeDepartment && <ProgressNotificationItem setData={setData} data={data.data} />;
	const popoverWrapper = children => (
		<Popover overlayClassName={'progress-notification__popover'} content={popoverContent} trigger="click">
			{children}
		</Popover>
	);
	const navBarContent = (
		<div className={'progress-notification'}>
			<ProgressUpload style={{ height: '17px', position: 'relative', top: '5px' }} />
			{data?.data && data?.data?.type !== 'progress' && (
				<Progress strokeWidth={4} showInfo={false} percent={Math.round((data?.data.completedDocsCount / data.data.totalDocsCount) * 100)} />
			)}
			{data?.data && data?.data?.type === 'progress' && (
				<Progress strokeWidth={5} status={data?.data?.remaining === 0 ? 'success' : 'active'} showInfo={false} percent={100} />
			)}
		</div>
	);

	return activeDepartment && data ? popoverWrapper(navBarContent) : null;
};

const ProgressNotificationItem = ({ data, setData }) => {
	if (!data) return null;
	const { completedDocsCount, totalDocsCount, title, estimatedLeftTime, type, remaining, avoidDismiss, subtitle } = data;
	const isDone = estimatedLeftTime === 0;
	const remainingData = isDone ? 'Done!' : `Remaining: ${Math.floor(estimatedLeftTime / 1000)} seconds`;

	const handleDismiss = () => setData(null);

	return (
		<div className={'progress-notification__popover__content'}>
			<h2>
				{title}
				{isDone && type !== 'progress' && ': Completed'}
			</h2>
			{/* {type === 'progress' && <h3>{`Position: ${remaining}`}</h3>} */}
			{type === 'progress' && subtitle && <h3>{`${subtitle}`}</h3>}
			{type === 'progress' && <Progress strokeWidth={10} status={remaining === 0 ? 'success' : 'active'} showInfo={false} percent={100} />}
			{type !== 'progress' && <h3>{`Progress: ${completedDocsCount} of ${totalDocsCount}`}</h3>}
			{type !== 'progress' && <Progress strokeWidth={2} showInfo={false} percent={Math.round((completedDocsCount / totalDocsCount) * 100)} />}
			{type !== 'progress' && <span>{remainingData}</span>}
			{(!avoidDismiss || remaining === 0) && <Button onClick={handleDismiss}>Dismiss</Button>}
		</div>
	);
};

export default ProgressNotification;
