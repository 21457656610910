import * as React from 'react';
const QuestionCircleOutlined = props => (
	<svg {...props} className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={props.size} height={props.size} fill="none">
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5ZM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12ZM12 7.75c-.621 0-1.125.504-1.125 1.125a.75.75 0 1 1-1.5 0 2.625 2.625 0 1 1 3.96 2.26c-.174.097-.33.222-.465.369-.102.12-.12.2-.12.246V13a.75.75 0 1 1-1.5 0v-1.25c0-.506.222-.916.477-1.217.252-.297.566-.524.845-.689A1.124 1.124 0 0 0 12 7.75ZM12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default QuestionCircleOutlined;
