import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CopilotProgressBar from './copilotProgressBar';
import { copilot } from './copilotSlice';
import './copilotProgress.less';

export default function CopilotProgress() {
	const dispatch = useDispatch();
	const [actions, setActions] = useState([]);
	const { user } = useSelector(state => state.auth);

	useEffect(() => {
		initListeners();
	}, [user]);

	return (
		<div className="copilot-progress-container">
			{actions.map((action, index) => (
				<CopilotProgressBar key={index} message={action?.message} onCanceled={() => removeAction(action)} onFinished={() => startAction(action)} />
			))}
		</div>
	);

	function initListeners() {
		dispatch(copilot.setFunctionListener({ event: 'starting', listener: startingListener }));
	}

	function startingListener(data) {
		setActions(prev => [...prev, data]);
	}

	function removeAction(action) {
		setActions(prev => prev.filter(a => a.id !== action.id));
	}

	function startAction(action) {
		dispatch(copilot.startAction(action));
		removeAction(action);
	}
}
