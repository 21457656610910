import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Timeline, Card, Button, Tabs, List, Tooltip } from 'antd';
import { CodeTwoTone, SmileTwoTone, CopyTwoTone } from '@ant-design/icons';
import { ReactComponent as Icon } from '../../assets/images/icons/chat.svg';
import notification from '../../shared/components/ui/Notification/Notification';
import './chatAssistantMessageDebugger.less';

const { REACT_APP_ENV: env } = process.env;

export default function ChatAssistantMessageDebugger({ opened }) {
	const { user } = useSelector(state => state.auth);
	const { logs, debuggingId } = useSelector(state => state.copilot);
	const [showHistory, setShowHistory] = useState(false);
	const [startingMessageIndex, setStartingMessageIndex] = useState(null);
	const listRef = useRef(null);

	useEffect(scrollEffect, [debuggingId, showHistory]);
	useEffect(getStartingIndexEffect, [debuggingId, logs]);

	const SETTINGS_TABS = [
		{
			key: 'messages',
			label: 'Messages',
			children: <Messages />,
		},
		{
			key: 'functions',
			label: 'Available functions',
			children: <AvailableFunctions />,
		},
	];

	return (
		opened &&
		debuggingId &&
		(user?.role === 'developer' || env !== 'production') && (
			<Card
				ref={listRef}
				title={`GPT completion, resolved by model: ${logs[debuggingId]?.completion?.model?.replace?.('-0613', '') || '4'}`}
				className="chat-assistant-message-debugger"
				extra={
					<Tooltip title={`Copying completion for ID: ${debuggingId}`}>
						<Button type="dashed" shape="circle" icon={<CopyTwoTone />} onClick={() => copyToClipboard(logs[debuggingId]?.completion)} />
					</Tooltip>
				}
			>
				<Tabs items={SETTINGS_TABS}></Tabs>
			</Card>
		)
	);

	function Messages() {
		return (
			<div>
				{!showHistory && startingMessageIndex > 0 && (
					<Button type="text" onClick={() => setShowHistory(true)}>
						Show more history
					</Button>
				)}
				<Timeline>
					{(logs[debuggingId]?.completion?.messages || []).map((log, i) => {
						return i < startingMessageIndex && !showHistory ? null : (
							<Timeline.Item key={i} color={log.role === 'user' ? 'blue' : 'green'} dot={getDotIcon(log)}>
								{log.content && log.content !== '-' && <p>{log.content}</p>}
								{log.function_call?.name && <p>Running function: {log.function_call.name}</p>}
								{log.function_call?.arguments && (
									<pre>
										<label>Arguments :</label>
										<code>{`${JSON.stringify(log.function_call.arguments, null, 2)}`}</code>
									</pre>
								)}
							</Timeline.Item>
						);
					})}
				</Timeline>
			</div>
		);
	}

	function AvailableFunctions() {
		return (
			<List
				itemLayout="horizontal"
				dataSource={logs[debuggingId]?.completion?.functions || []}
				renderItem={item => (
					<List.Item>
						<Card title={item.name}>
							<p>{item.description}</p>
							{item.parameters && (
								<pre>
									<code>{`${JSON.stringify(item.parameters, null, 2)}`}</code>
								</pre>
							)}
						</Card>
					</List.Item>
				)}
			/>
		);
	}

	function scrollEffect() {
		const timeout = setTimeout(() => {
			listRef?.current?.querySelector?.('ul')?.lastChild?.scrollIntoView?.({ behavior: 'smooth' });
		}, 200);
		return () => clearTimeout(timeout);
	}

	function getDotIcon(message) {
		if (message?.role === 'user') {
			return (
				<Tooltip title={`User says:`} placement="left">
					<SmileTwoTone />
				</Tooltip>
			);
		}
		if (message?.role === 'assistant') {
			return (
				<Tooltip title={`assistant says:`} placement="left">
					<Icon className="chat-icon" />
				</Tooltip>
			);
		}
		return (
			<Tooltip title={`${message?.role} says:`} placement="left">
				<CodeTwoTone twoToneColor={message?.role === 'system' ? '#52c41a' : null} />
			</Tooltip>
		);
	}

	function getStartingIndexEffect() {
		if (!debuggingId) {
			setStartingMessageIndex(null);
			return;
		}
		const index = logs[debuggingId]?.completion?.messages?.findLastIndex?.(m => m?.role === 'user');
		setStartingMessageIndex(index);
	}

	async function copyToClipboard(value) {
		if (!value) {
			return;
		}
		if (typeof value !== 'string') {
			value = JSON.stringify(value, null, 2);
		}
		await navigator.clipboard.writeText(value);

		notification('info', `Copied to clipboard`);
	}
}
