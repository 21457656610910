import React from 'react';
import { Table } from 'antd';
import './deniedStages.less';

const columns = [
	{
		title: 'Stage Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Comments',
		dataIndex: 'comments',
		key: 'comments',
	},
];

export default function DeniedStagesList({ stagesDenied }) {
	const stagesDeniedCount = stagesDenied?.reduce(
		(acc, curr) => acc + (curr.subStages ? curr.subStages?.length : 1),
		0,
	);

	return (
		<div className="denied-stages">
			<div className="denied-stages__types">
				<div className="stages-title">
					Denied Stages <span>{stagesDeniedCount}</span>
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={stagesDenied.map(stage =>
					stage?.subStages
						? {
								...stage,
								children: stage.subStages.map(sub => ({ ...sub, key: sub.name })),
								key: stage.name,
						  }
						: stage,
				)}
				pagination={false}
			/>
		</div>
	);
}
