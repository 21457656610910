import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { copilot } from './copilotSlice';
import ChatAssistantContextItem from './chatAssistantContextItem';
import './chatAssistantContext.less';

export default function ChatAssistantContext({ opened }) {
	const dispatch = useDispatch();
	const { contexts } = useSelector(state => state.copilot);

	useEffect(initialEffect, []);

	return (
		<div className={`chat-context ${opened ? 'opened' : ''}`}>
			{contexts.map((context, i) => (
				<ChatAssistantContextItem key={i} context={context} />
			))}
		</div>
	);

	function initialEffect() {
		dispatch(copilot.setFunctionListener({ event: 'context', listener: onContext }));

		return () => {
			dispatch(copilot.removeListener('context'));
		};
	}

	function onContext(contexts) {
		dispatch(copilot.setContexts(contexts));
	}
}
