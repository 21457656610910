import * as React from 'react';
const Recruitment = props => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width={16} height={16} fill="none">
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M11.197 2.95c-2.546 0-4.592 2.028-4.592 4.52 0 2.494 2.047 4.528 4.592 4.528a.95.95 0 1 1 0 1.9c-3.571 0-6.492-2.862-6.492-6.428 0-3.567 2.923-6.42 6.492-6.42a6.49 6.49 0 0 1 5.837 3.613.95.95 0 0 1-1.705.838 4.59 4.59 0 0 0-4.132-2.551Z"
			clipRule="evenodd"
		/>
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M11.197 13.898c-4.567 0-8.247 3.652-8.247 8.14a.95.95 0 1 1-1.9 0c0-5.558 4.551-10.04 10.147-10.04a.95.95 0 1 1 0 1.9Zm8.035-5.873c-2.547 0-4.593 2.037-4.593 4.527s2.046 4.528 4.593 4.528 4.593-2.038 4.593-4.528c0-2.49-2.046-4.527-4.593-4.527Zm-6.493 4.527c0-3.56 2.918-6.427 6.493-6.427s6.493 2.867 6.493 6.427c0 3.56-2.918 6.428-6.493 6.428s-6.493-2.867-6.493-6.428Z"
			clipRule="evenodd"
		/>
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M19.232 18.972c-4.568 0-8.247 3.653-8.247 8.14a.95.95 0 0 1-1.9 0c0-5.558 4.55-10.04 10.147-10.04a.95.95 0 1 1 0 1.9Zm5.316-.28a.95.95 0 0 1 .95.95v7.94a.95.95 0 1 1-1.9 0v-7.94a.95.95 0 0 1 .95-.95Z"
			clipRule="evenodd"
		/>
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M19.58 23.612a.95.95 0 0 1 .95-.95h8.035a.95.95 0 0 1 0 1.9h-8.034a.95.95 0 0 1-.95-.95Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default Recruitment;
