import React from 'react'

export const PhoneIcon = ({className}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.65535 2.52886C3.46668 1.7222 4.80268 1.86553 5.48202 2.77353L6.32335 3.8962C6.87668 4.63486 6.82735 5.66686 6.17068 6.31953L6.01202 6.4782C5.9941 6.54482 5.99227 6.61474 6.00669 6.6822C6.04869 6.9542 6.27602 7.5302 7.22802 8.47686C8.18002 9.42353 8.76002 9.6502 9.03602 9.69286C9.10549 9.70764 9.1775 9.70558 9.24602 9.68686L9.51802 9.4162C10.102 8.8362 10.998 8.72753 11.7207 9.1202L12.994 9.81353C14.0854 10.4055 14.3607 11.8882 13.4674 12.7769L12.52 13.7182C12.2214 14.0149 11.82 14.2622 11.3307 14.3082C10.124 14.4209 7.31269 14.2769 4.35735 11.3389C1.59935 8.5962 1.07002 6.2042 1.00268 5.02553C0.969351 4.42953 1.25068 3.92553 1.60935 3.56953L2.65535 2.52886ZM4.68202 3.37286C4.34402 2.92153 3.71468 2.88553 3.36002 3.2382L2.31335 4.2782C2.09335 4.49686 1.98802 4.7382 2.00135 4.96886C2.05468 5.90553 2.48135 8.06353 5.06269 10.6302C7.77068 13.3222 10.2714 13.4029 11.238 13.3122C11.4354 13.2942 11.6314 13.1915 11.8147 13.0095L12.7614 12.0675C13.1467 11.6849 13.062 10.9875 12.5167 10.6915L11.2434 9.99886C10.8914 9.8082 10.4794 9.87086 10.2234 10.1255L9.92002 10.4275L9.56668 10.0729C9.92002 10.4275 9.91935 10.4282 9.91868 10.4282L9.91802 10.4295L9.91602 10.4315L9.91135 10.4355L9.90135 10.4449C9.87301 10.4707 9.84269 10.4944 9.81068 10.5155C9.75735 10.5509 9.68668 10.5902 9.59802 10.6229C9.41802 10.6902 9.17935 10.7262 8.88468 10.6809C8.30668 10.5922 7.54068 10.1982 6.52269 9.1862C5.50535 8.1742 5.10802 7.41286 5.01868 6.83553C4.97268 6.54086 5.00935 6.3022 5.07735 6.1222C5.11506 6.02104 5.16862 5.92653 5.23602 5.8422L5.25735 5.81886L5.26668 5.80886L5.27069 5.80486L5.27269 5.80286L5.27402 5.80153L5.46602 5.61086C5.75135 5.3262 5.79135 4.85486 5.52269 4.49553L4.68202 3.37286Z" fill="black" />
        </svg>
    )
}

export default PhoneIcon;
