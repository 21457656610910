import React from 'react'

export const LocationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.16663 6.76201C2.16663 3.49601 4.76996 0.833344 7.99996 0.833344C11.23 0.833344 13.8333 3.49601 13.8333 6.76201C13.8333 8.33868 13.384 10.032 12.5893 11.4947C11.796 12.9553 10.6373 14.2247 9.18663 14.9027C8.81517 15.0765 8.41007 15.1666 7.99996 15.1666C7.58985 15.1666 7.18475 15.0765 6.81329 14.9027C5.36263 14.2247 4.20396 12.956 3.41063 11.4947C2.61596 10.032 2.16663 8.33868 2.16663 6.76201ZM7.99996 1.83334C5.33863 1.83334 3.16663 4.03134 3.16663 6.76201C3.16663 8.16001 3.56863 9.69001 4.28929 11.0173C5.01063 12.346 6.03063 13.4333 7.23596 13.9967C7.47497 14.1087 7.73568 14.1667 7.99963 14.1667C8.26357 14.1667 8.52428 14.1087 8.76329 13.9967C9.96929 13.4333 10.9893 12.346 11.7106 11.0173C12.4313 9.69068 12.8333 8.16001 12.8333 6.76201C12.8333 4.03134 10.6613 1.83334 7.99996 1.83334ZM7.99996 5.16668C7.80298 5.16668 7.60792 5.20548 7.42593 5.28086C7.24395 5.35624 7.07859 5.46673 6.9393 5.60602C6.80001 5.7453 6.68952 5.91066 6.61414 6.09265C6.53876 6.27464 6.49996 6.46969 6.49996 6.66668C6.49996 6.86366 6.53876 7.05871 6.61414 7.2407C6.68952 7.42269 6.80001 7.58805 6.9393 7.72734C7.07859 7.86662 7.24395 7.97711 7.42593 8.0525C7.60792 8.12788 7.80298 8.16668 7.99996 8.16668C8.39778 8.16668 8.77931 8.00864 9.06062 7.72734C9.34192 7.44603 9.49996 7.0645 9.49996 6.66668C9.49996 6.26885 9.34192 5.88732 9.06062 5.60602C8.77931 5.32471 8.39778 5.16668 7.99996 5.16668ZM5.49996 6.66668C5.49996 6.00364 5.76335 5.36775 6.23219 4.89891C6.70103 4.43007 7.33692 4.16668 7.99996 4.16668C8.663 4.16668 9.29889 4.43007 9.76773 4.89891C10.2366 5.36775 10.5 6.00364 10.5 6.66668C10.5 7.32972 10.2366 7.9656 9.76773 8.43444C9.29889 8.90329 8.663 9.16668 7.99996 9.16668C7.33692 9.16668 6.70103 8.90329 6.23219 8.43444C5.76335 7.9656 5.49996 7.32972 5.49996 6.66668Z" fill="black" />
        </svg>
    )
}

export default LocationIcon;
