import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import './EditableSettingsCard.less';
import { Button, Form, Input, notification, Select } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import { FIELD_LABELS, PLACEHOLDER_VALUES } from '../utils';
import { PhoneFormItem } from '../../../shared/components/FormItemByType/FormItemPhone';
import Modal from 'antd/es/modal/Modal';
import clsx from 'clsx';
import get from 'lodash/get';
import request from '../../../utils/API/apiHandler';

const { Option } = Select;

const EditableSettingsCard = props => {
	const [form] = useForm();
	const [editing, setEditing] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const isEditing = props.editing || editing;

	useEffect(() => {
		form.setFieldsValue(props.value);
	}, [editing]);

	const { type } = props;

	const valuePropName = props.type === 'Office' ? 'offices' : 'contacts';

	const handleUpdate = async () => {
		setDisabled(true);
		try {
			await form.validateFields();
			const data = await request({
				url: `/company-profile/${props.value._id}`,
				data: { [valuePropName]: form.getFieldsValue() },
				method: 'patch',
			});
			props.onChange(data);
			setEditing(false);
			notification.info({ message: `${props.type} updated` });
		} catch (err) {
			if (err.errorFields) return;
			err && notification.error({ message: err.message });
		} finally {
			setDisabled(false);
		}
	};

	const handleRemove = async () => {
		setDisabled(true);
		try {
			await request({
				url: `/company-profile/${props.value._id}`,
				method: 'delete',
				data: { [valuePropName]: { id: props?.value._id } },
			});
			props.operation.remove(props.name);
			notification.info({ message: `${props.type} removed` });
		} catch (err) {
			if (err.errorFields) return;
			err && notification.error({ message: err });
			props.operation.remove(props.key);
		} finally {
			setDisabled(false);
		}
	};

	const handleCreate = async () => {
		setDisabled(true);
		try {
			await form.validateFields();
			const data = await request({
				url: `/company-profile/`,
				method: 'post',
				data: { [valuePropName]: form.getFieldsValue() },
			});
			props.operation.add(data);
			props.destroy();
			notification.info({ message: `${props.type} created` });
		} catch (err) {
			if (err.errorFields) return;
			const message = get(err, 'response.data.message');
			message && notification.error({ message });
		} finally {
			setDisabled(false);
		}
	};

	const mapFormFields = () => {
		const selectHashOptionsArr = {
			title: ['Owner', 'CEO', 'Administrator', 'CFO', 'CTO', 'CCO', 'CMO', 'RN', 'Manager / Supervisor', 'Human Resources Manager', 'Other'],
			contactType: ['Admin contact', 'Billing contact'],
		};

		const selectWithSameNameValue = field => {
			return (
				<Select showSearch optionFilterProp="children" placeholder="Select">
					{[...selectHashOptionsArr[field]].map(val => (
						<Option key={val} value={val}>
							{val}
						</Option>
					))}
				</Select>
			);
		};

		return Object.keys(props.value || PLACEHOLDER_VALUES[type]).map(field => {
			const rules = [{ required: true, message: `${FIELD_LABELS[field]} is required` }];

			if (field === '_id') {
				return null;
			} else if (field === 'phone') {
				const phoneProps = { itemName: field, itemLabel: FIELD_LABELS[field], isRequired: true };
				return <PhoneFormItem key={field} {...phoneProps} />;
			} else if (field === 'title' || field === 'contactType') {
				return (
					<Form.Item key={field} rules={rules} name={field} label={FIELD_LABELS[field]}>
						{selectWithSameNameValue(field)}
					</Form.Item>
				);
			} else if (field === 'email') {
				const emailRules = [...rules, { type: 'email', required: true, message: 'Please enter a valid email' }];
				return (
					<Form.Item key={field} rules={emailRules} name={field} label={FIELD_LABELS[field]}>
						<Input placeholder="Type here" />
					</Form.Item>
				);
			} else
				return (
					<Form.Item rules={rules} name={field} label={FIELD_LABELS[field]}>
						<Input placeholder="Type here" />
					</Form.Item>
				);
		});
	};

	const mapNonEditableFields = () => {
		return Object.keys(props.value || PLACEHOLDER_VALUES[type]).map(field => {
			if (field === '_id' || field === 'officeName') return null;
			else
				return (
					<div>
						<span>{FIELD_LABELS[field]}:</span> <strong>{props.value ? props.value[field] : ''}</strong>
					</div>
				);
		});
	};

	const titleText = props.type === 'Office' ? props.value?.officeName : `${props.value?.firstName} ${props.value?.lastName}`;

	const editable = (
		<div
			className={clsx(
				'satellite-office-editable',
				{ 'satellite-office-editable-modal': props.create },
				{ 'satellite-office-editable-office': props.type === 'Office' },
				{ 'satellite-office-editable-contact': props.type === 'Contact' },
			)}
		>
			<Form form={form} layout="vertical">
				{mapFormFields()}
			</Form>
			{!props.create && (
				<div className="satellite-office-editable__footer">
					<div>
						<Button onClick={handleRemove} disabled={disabled} danger type="link">
							Delete {type}
						</Button>
					</div>
					<div>
						<Button type="link" onClick={() => setEditing(false)}>
							Cancel
						</Button>
						<Button disabled={disabled} type="primary" onClick={handleUpdate}>
							Update
						</Button>
					</div>
				</div>
			)}
		</div>
	);

	const nonEditable = (
		<div className={'settings-form-non-editable'}>
			<div>
				<h3>{titleText}</h3>
				<EditOutlined onClick={() => setEditing(editing => !editing)} />
			</div>
			<div>{mapNonEditableFields()}</div>
		</div>
	);

	const modalForm = child => (
		<Modal wrapClassName={'editable-settings-modal'} open onCancel={props.destroy} onOk={handleCreate} okText="Create" title={`Create ${props.type}`}>
			{child}
		</Modal>
	);

	if (props.create) return modalForm(editable);
	else return isEditing ? editable : nonEditable;
};

export default EditableSettingsCard;
