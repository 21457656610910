import React from 'react'

export const UploadIcon = ({ color = 'currentColor', className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.16665 5.99858C5.16818 6.27472 4.94556 6.49981 4.66942 6.50134C3.9405 6.50536 3.42376 6.52436 3.03164 6.59644C2.65376 6.6659 2.43507 6.77737 2.27288 6.93956C2.08823 7.12422 1.9679 7.38333 1.90212 7.87241C1.83439 8.37592 1.83333 9.0432 1.83333 10V10.6667C1.83333 11.6238 1.83439 12.2911 1.90212 12.7946C1.96789 13.2836 2.08821 13.5429 2.27308 13.728C2.45727 13.9121 2.71622 14.0322 3.2054 14.098C3.70904 14.1656 4.37654 14.1667 5.33333 14.1667H10.6667C11.6235 14.1667 12.291 14.1656 12.7946 14.098C13.284 14.0322 13.5429 13.912 13.7271 13.7278C13.9118 13.5431 14.0321 13.284 14.0979 12.7949C14.1656 12.2913 14.1667 11.6238 14.1667 10.6667V10C14.1667 9.0432 14.1656 8.37592 14.0979 7.87241C14.0321 7.38333 13.9118 7.12422 13.7271 6.93956C13.5649 6.77737 13.3462 6.6659 12.9684 6.59644C12.5762 6.52436 12.0595 6.50536 11.3306 6.50134C11.0544 6.49981 10.8318 6.27472 10.8333 5.99858C10.8349 5.72245 11.0599 5.49983 11.3361 5.50135C12.0572 5.50533 12.6581 5.52266 13.1491 5.61291C13.6544 5.70579 14.0844 5.88264 14.4342 6.23246C14.8356 6.6338 15.0082 7.13902 15.089 7.73911C15.1667 8.31705 15.1667 9.05184 15.1667 9.96341V10.7033C15.1667 11.6152 15.1667 12.3501 15.089 12.9282C15.0082 13.5283 14.8356 14.0335 14.4342 14.4349C14.0331 14.836 13.5277 15.0085 12.9277 15.0891C12.3497 15.1667 11.6148 15.1667 10.7032 15.1667H5.29679C4.38517 15.1667 3.65025 15.1667 3.07226 15.0891C2.47228 15.0085 1.96691 14.836 1.56578 14.4349C1.16465 14.0333 0.991767 13.5281 0.911042 12.9279C0.833304 12.35 0.833315 11.6152 0.833329 10.7033V9.96342C0.833315 9.05184 0.833304 8.31705 0.911042 7.73911C0.991756 7.13902 1.16443 6.6338 1.56578 6.23246C1.91559 5.88264 2.34557 5.70579 2.85086 5.61291C3.3419 5.52266 3.94282 5.50533 4.6639 5.50135C4.94004 5.49983 5.16513 5.72245 5.16665 5.99858Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99999 0.833344C8.14596 0.833344 8.28463 0.897125 8.37962 1.00795L10.3796 3.34128C10.5593 3.55094 10.5351 3.86659 10.3254 4.04631C10.1157 4.22602 9.80008 4.20174 9.62037 3.99207L8.49999 2.68497V10C8.49999 10.2762 8.27614 10.5 7.99999 10.5C7.72385 10.5 7.49999 10.2762 7.49999 10V2.68497L6.37962 3.99207C6.19991 4.20174 5.88426 4.22602 5.6746 4.04631C5.46494 3.86659 5.44065 3.55094 5.62037 3.34128L7.62037 1.00795C7.71536 0.897125 7.85403 0.833344 7.99999 0.833344Z" fill={color} />
        </svg>
    )
}

export default UploadIcon;
