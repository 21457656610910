import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { copilot } from './copilotSlice';
import CopilotAction from './copilotAction';
import { ExperimentTwoTone } from '@ant-design/icons';
import { Card } from 'antd';
import './copilotActions.less';
import notification from '../../assets/audios/notification.wav';

export default function CopilotActions() {
	const dispatch = useDispatch();
	const { actions } = useSelector(state => state.copilot);
	const [open, setOpen] = useState(false);
	const [lastActionLength, setLastActionLength] = useState(0);

	useEffect(() => {
		initializeListeners();
		return () => copilot.removeListener('status');
	}, []);

	useEffect(() => {
		return () => setLastActionLength(actions?.length);
	}, [actions]);

	return (
		<div className={`copilot-actions ${actions?.length ? '' : 'no-actions'} ${open ? '' : 'closed'}`}>
			<ExperimentTwoTone
				className={`${open ? '' : 'closed'} ${lastActionLength < actions?.length ? 'added' : ''}`}
				onClick={() => setOpen(prev => !prev)}
			/>
			{
				<Card className={`copilot-actions-container`}>
					{actions?.map?.((action, i) => (
						<CopilotAction key={i} action={action} />
					))}
				</Card>
			}
		</div>
	);

	function initializeListeners() {
		dispatch(copilot.setFunctionListener({ event: 'status', listener: statusListener }));
	}

	function statusListener(action, status) {
		switch (status) {
			case 'finished':
				dispatch(copilot.setFinished(action));
				setTimeout(() => {
					dispatch(copilot.removeAction(action));
				}, 1000 * 30);
				break;
			case 'started':
				new Audio(notification).play();
			default:
				dispatch(copilot.addAction({ ...action, status }));
				break;
		}
	}
}
