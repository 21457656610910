import dayjs from 'dayjs';
import { subQuestioRenderCondition } from '../FormInnerQuestions/renderConditions';

// Check if parent question's trigger conditions are met
const isParentQuestionTriggerComplete = (parentQuestion, answersHash, validateUndefined) => {
	const { when, value } = parentQuestion?.trigger || {};
	const answer = answersHash[parentQuestion._id]?.value;

	if (!when && validateUndefined && (!answer || dayjs(answer).format() === 'Invalid Date')) {
		return false;
	}

	return (
		(!when && validateUndefined && answer !== value) ||
		(when === 'notEquals' && answer === value) ||
		(when === 'equals' && answer !== value) ||
		(when === 'length' && Array.isArray(answer) && answer.length > value) ||
		(when === 'expires' && !Array.isArray(answer) && Date.now() - new Date(answer).getTime() < Number(value) * 1000 * 60 * 60 * 24)
	);
};

// Check if all subquestions are complete
const areSubquestionsComplete = (subQuestions, parentQuestion, answersHash) => {
	if (!subQuestions || subQuestions.length === 0) {
		return true;
	}

	return subQuestions.reduce((acc, subQuestion) => {
		if (subQuestion.required && subQuestioRenderCondition(answersHash, parentQuestion, subQuestion)) {
			return Boolean(answersHash[subQuestion._id]?.value);
		}
		return acc;
	}, true);
};

// Check if parent question is complete
const isParentQuestionComplete = (parentQuestion, answersHash) => {
	const answer = answersHash[parentQuestion._id]?.value;
	const isParentQuestionComplete = !(answer === undefined || (Array.isArray(answer) && answer.length === 0));
	return isParentQuestionComplete;
};

export const calculateDocumentsStatus = (questions, answersHash, validateUndefined = false) => {
	const documentsState = questions.reduce((acc, parentQuestion) => {
		const parentComplete = isParentQuestionComplete(parentQuestion, answersHash);
		const triggerComplete = isParentQuestionTriggerComplete(parentQuestion, answersHash, validateUndefined);
		const subquestionsComplete = areSubquestionsComplete(parentQuestion.subQuestions, parentQuestion, answersHash);

		acc[parentQuestion.question] = parentQuestion.required ? parentComplete && triggerComplete && subquestionsComplete : parentComplete;
		return acc;
	}, {});

	return documentsState;
};
