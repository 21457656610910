import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import notification from '../../shared/components/ui/Notification/Notification';
import request from '../../../utils/API/apiHandler';
import queryString from 'query-string';
import { flattenObject } from '../../Department/TicketsList/utils';
import { notification } from 'antd';

const initialState = {
	searchQuery: '',
	data: null,
	loading: false,
	error: false,
	page: 1,
	pageSize: 10,
	isLocalPagination: false,
	managementLinks: null,
	linksLoading: false,
	metaData: null,
	metaLoading: false,
	currentLink: null,
	selectedHeader: null,
	submitLoading: false,
};

export const getAllData = createAsyncThunk('management/get-data', async (payload, { getState }) => {
	const { page, pageSize, currentLink, selectedHeader, searchQuery: search } = getState().managementReducer;
	const filter = selectedHeader?.filter || {};
	let queryParams = { page: page - 1, pageSize, search, ...filter };

	const data = queryString.stringify(flattenObject(queryParams), { arrayFormat: 'bracket' });

	const responseData = await request({ url: `/${currentLink?.endpoint}/?${data ? data : ''}`, zeroState: [] });

	return responseData || { data: [], total: 0 };
});

export const getManagementLinks = createAsyncThunk('management/get-management-links', async managementPath => {
	const responseData = await request({ url: 'management/meta/links' });

	return {
		managementLinks: responseData,
		currentLink: responseData?.find(link => link?.path === managementPath),
	};
});

export const getMetaData = createAsyncThunk('management/get-meta-data', (payload, { getState }) => {
	const { currentLink } = getState().managementReducer;
	return request({ url: `${currentLink.metadataEndpoint}` });
});

export const dataSubmit = createAsyncThunk('management/data-submit', async payload => {
	const { action, data } = payload;
	let api = 'mainAPI';

	let actionEndpoint = action.endpoint;
	if (data.actionForm?._blobFile instanceof File) {
		api = 'uploadMainAPI';
		data.file = data.actionForm?._blobFile;
		delete data.actionForm?._blobFile;
		if (!action?.endpoint?.endsWith('/file')) {
			actionEndpoint = `${action.endpoint}/file`;
		}
	}

	const response = await request({ api, url: actionEndpoint, method: action?.type, data, notify: true });
	response?.message &&
		notification.success({
			message: response.message,
		});
	return response ? Promise.resolve() : Promise.reject();
});

const management = createSlice({
	name: 'managementReducer',
	initialState,
	reducers: {
		setSearchQuery: (state, { payload }) => {
			state.searchQuery = payload;
			state.currentPage = 1;
		},
		setIsLocalPagination: (state, { payload }) => {
			state.isLocalPagination = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setPageSize: (state, { payload }) => {
			state.pageSize = payload;
		},
		switchTab: (state, { payload }) => {
			state.selectedHeader = payload;
		},
	},
	extraReducers: {
		[getAllData.pending]: (state, { payload }) => {
			state.loading = true;
		},
		[getAllData.rejected]: (state, { payload }) => {
			state.loading = false;
		},
		[getAllData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.data = payload;
		},
		[getManagementLinks.pending]: (state, { payload }) => {
			state.linksLoading = true;
		},
		[getManagementLinks.rejected]: (state, { payload }) => {
			state.linksLoading = false;
		},
		[getManagementLinks.fulfilled]: (state, { payload }) => {
			state.linksLoading = false;
			state.managementLinks = payload.managementLinks;
			state.currentLink = payload.currentLink;
		},
		[getMetaData.pending]: (state, { payload }) => {
			state.metaLoading = true;
		},
		[getMetaData.rejected]: (state, { payload }) => {
			state.metaLoading = false;
		},
		[getMetaData.fulfilled]: (state, { payload }) => {
			state.metaLoading = false;
			state.metaData = payload;
			if (!state.selectedHeader && payload?.defaultHeader) state.selectedHeader = payload?.defaultHeader;
		},
		[dataSubmit.pending]: (state, { payload }) => {
			state.submitLoading = true;
		},
		[dataSubmit.rejected]: (state, { payload }) => {
			state.submitLoading = false;
		},
		[dataSubmit.fulfilled]: (state, { payload }) => {
			state.submitLoading = false;
		},
	},
});

export const { reducer, actions } = management;
