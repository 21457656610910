import React from 'react'

export const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M10.486 2.58932C11.442 1.62932 12.8407 1.60932 13.6147 2.38666C14.3907 3.16532 14.37 4.57332 13.4127 5.53332L11.7973 7.15532C11.7064 7.24975 11.6562 7.37613 11.6575 7.50723C11.6588 7.63833 11.7116 7.76367 11.8044 7.85624C11.8972 7.94882 12.0227 8.00123 12.1538 8.00218C12.2849 8.00313 12.4112 7.95254 12.5053 7.86132L14.1213 6.23932C15.3953 4.95999 15.5553 2.91799 14.3233 1.68066C13.09 0.442657 11.052 0.60399 9.77667 1.88332L6.54601 5.12799C5.27201 6.40732 5.11201 8.44932 6.34401 9.68599C6.39002 9.73384 6.4451 9.77205 6.50604 9.79839C6.56698 9.82473 6.63255 9.83867 6.69893 9.8394C6.76531 9.84012 6.83117 9.82762 6.89267 9.80263C6.95417 9.77763 7.01008 9.74064 7.05713 9.69381C7.10418 9.64698 7.14144 9.59125 7.16672 9.52987C7.19201 9.46848 7.20482 9.40268 7.2044 9.3363C7.20399 9.26991 7.19036 9.20428 7.16431 9.14322C7.13826 9.08216 7.10031 9.0269 7.05267 8.98066C6.27667 8.20199 6.29801 6.79399 7.25467 5.83399L10.486 2.58932Z" fill="black" />
            <path d="M9.65665 6.31332C9.56294 6.21935 9.43573 6.16645 9.30302 6.16626C9.17031 6.16607 9.04296 6.21861 8.94898 6.31232C8.85501 6.40603 8.80211 6.53324 8.80192 6.66595C8.80173 6.79866 8.85427 6.92601 8.94798 7.01999C9.72398 7.79866 9.70331 9.20599 8.74598 10.1667L5.51465 13.4107C4.55798 14.3707 3.15931 14.3907 2.38531 13.6133C1.60931 12.8347 1.63065 11.4267 2.58731 10.4667L4.20331 8.84466C4.24967 8.79813 4.28641 8.74292 4.31143 8.68219C4.33645 8.62146 4.34926 8.5564 4.34914 8.49071C4.34902 8.42503 4.33596 8.36002 4.31071 8.29938C4.28546 8.23875 4.24851 8.18368 4.20198 8.13732C4.15545 8.09097 4.10024 8.05423 4.03951 8.02921C3.97878 8.00419 3.91372 7.99137 3.84804 7.9915C3.78236 7.99162 3.71734 8.00468 3.65671 8.02993C3.59607 8.05518 3.541 8.09212 3.49465 8.13866L1.87865 9.76066C0.604647 11.0407 0.444647 13.082 1.67665 14.3193C2.90998 15.558 4.94798 15.396 6.22331 14.1167L9.45465 10.872C10.7286 9.59332 10.8886 7.54999 9.65665 6.31332Z" fill="black" />
        </svg>
    )
}

export default LinkIcon;
