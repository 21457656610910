import { push, replace } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../utils/API/apiHandler';
import { socket } from '../../utils/socketHandler';

const user = JSON.parse(localStorage.getItem('user'));
const token = localStorage.getItem('token');

const initialState = {
	loggedIn: !!(token && user),
	token: token || null,
	user: user || null,
	loading: false,
};

export const handleLoginReq = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
	const result = await request({
		url: 'users/login',
		data: credentials,
		method: 'post',
		fallbackData: {},
	});
	const decodedToken = jwtDecode(result.token);
	// TODO: allow in production just for testing
	const avoidChat = decodedToken?.role !== 'developer' && decodedToken?.email !== 'joes@thrivedatainc.com';
	Object.assign(decodedToken, { avoidChat });
	return Promise.resolve({ user: decodedToken, token: result.token });
});

export const login = credentials => dispatch => {
	dispatch(handleLoginReq(credentials))
		.then(res => {
			if (!res.error) {
				dispatch(replace(localStorage.getItem('redirectedFromUrl') || '/'));
				localStorage.removeItem('redirectedFromUrl');
				setTimeout(() => {
					document.location.reload();
				}, 1);
			}
		})
		.catch(err => {
			dispatch(push('/500'));
		});
};

export const logout = () => dispatch => {
	const currentLocation = window.location.href;
	dispatch(actions.logout());
	if (!currentLocation.includes('forgot-password') && !currentLocation.includes('login')) {
		dispatch(replace('/login'));
	}
};

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			state.loggedIn = false;
			state.token = null;
			state.user = null;
			state.loading = false;
			socket.auth = { token: null };
			socket.disconnect();
		},
	},
	extraReducers: {
		[handleLoginReq.rejected]: (state, action) => {
			state.loading = false;
		},
		[handleLoginReq.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.user = payload.user;
			state.token = payload.token;
			state.loggedIn = true;
			localStorage.setItem('user', JSON.stringify(payload.user));
			localStorage.setItem('token', payload.token);
			socket.auth = { token: payload.token };
			socket.connect();
		},
		[handleLoginReq.pending]: (state, action) => {
			state.loading = true;
		},
	},
});

export const { reducer, actions } = auth;
