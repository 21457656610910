import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { history, persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import setTabTitle from './utils/tabTitle';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

setTabTitle();

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],
	ignoreErrors: ['ResizeObserver loop limit exceeded'],
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 0,
	environment: process.env.REACT_APP_ENV,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready
		.then(registration => {
			registration.unregister();

			if (caches) {
				// Service worker cache should be cleared with caches.delete()
				caches.keys().then(async names => {
					await Promise.all(names.map(name => caches.delete(name)));
				});
			}
		})
		.catch(error => {
			console.error(error.message);
		});
}
