export const FIELD_LABELS = {
	city: 'City',
	officeName: 'Office Name',
	postalCode: 'Postal Code',
	state: 'State',
	streetAddress: 'Street Address',
	firstName: 'First Name',
	lastName: 'Last Name',
	title: 'Title',
	email: 'Email',
	phone: 'Phone',
	ext: 'Extension',
	contactType: 'Contact Type',
};
export const PLACEHOLDER_VALUES = {
	Office: {
		officeName: '',
		streetAddress: '',
		state: '',
		city: '',
		postalCode: '',
	},
	Contact: {
		firstName: '',
		lastName: '',
		title: '',
		contactType: '',
		ext: '',
		phone: '',
		email: '',
	},
};
