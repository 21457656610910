import React from 'react'

const ClockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.83334C6.36454 1.83334 4.79602 2.48304 3.63955 3.63952C2.48307 4.79599 1.83337 6.36451 1.83337 8.00001C1.83337 9.63551 2.48307 11.204 3.63955 12.3605C4.79602 13.517 6.36454 14.1667 8.00004 14.1667C9.63554 14.1667 11.2041 13.517 12.3605 12.3605C13.517 11.204 14.1667 9.63551 14.1667 8.00001C14.1667 6.36451 13.517 4.79599 12.3605 3.63952C11.2041 2.48304 9.63554 1.83334 8.00004 1.83334ZM0.833374 8.00001C0.833374 4.04201 4.04204 0.833344 8.00004 0.833344C11.958 0.833344 15.1667 4.04201 15.1667 8.00001C15.1667 11.958 11.958 15.1667 8.00004 15.1667C4.04204 15.1667 0.833374 11.958 0.833374 8.00001ZM8.00004 4.83334C8.13265 4.83334 8.25983 4.88602 8.35359 4.97979C8.44736 5.07356 8.50004 5.20074 8.50004 5.33334V7.79334L10.02 9.31334C10.0692 9.35912 10.1086 9.41432 10.1359 9.47565C10.1632 9.53698 10.1779 9.60319 10.1791 9.67033C10.1803 9.73746 10.1679 9.80415 10.1428 9.86641C10.1176 9.92867 10.0802 9.98522 10.0327 10.0327C9.98525 10.0802 9.9287 10.1176 9.86644 10.1428C9.80418 10.1679 9.73749 10.1803 9.67036 10.1791C9.60322 10.1779 9.53701 10.1632 9.47568 10.1359C9.41435 10.1085 9.35915 10.0691 9.31337 10.02L7.64671 8.35334C7.55291 8.25967 7.50016 8.13257 7.50004 8.00001V5.33334C7.50004 5.20074 7.55272 5.07356 7.64649 4.97979C7.74026 4.88602 7.86743 4.83334 8.00004 4.83334Z" fill="black" />
        </svg>
    )
}

export default ClockIcon;
