import * as React from 'react';
const Dashboard = props => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} fill="none">
		<path
			fill={props.fill || 'currentColor'}
			d="M7.75 9a.75.75 0 0 0-1.5 0v9a.75.75 0 1 0 1.5 0V9ZM12 5.25a.75.75 0 0 1 .75.75v12a.75.75 0 1 1-1.5 0V6a.75.75 0 0 1 .75-.75ZM17.75 13a.75.75 0 1 0-1.5 0v5a.75.75 0 1 0 1.5 0v-5Z"
		/>
		<path
			fill={props.fill || 'currentColor'}
			fillRule="evenodd"
			d="M11.943 1.25c-2.309 0-4.118 0-5.53.19-1.444.194-2.584.6-3.479 1.494-.895.895-1.3 2.035-1.494 3.48-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53.194 1.444.6 2.584 1.494 3.479.895.895 2.035 1.3 3.48 1.494 1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19 1.444-.194 2.584-.6 3.479-1.494.895-.895 1.3-2.035 1.494-3.48.19-1.411.19-3.22.19-5.529v-.114c0-2.309 0-4.118-.19-5.53-.194-1.444-.6-2.584-1.494-3.479-.895-.895-2.035-1.3-3.48-1.494-1.411-.19-3.22-.19-5.529-.19h-.114ZM3.995 3.995c.57-.57 1.34-.897 2.619-1.069 1.3-.174 3.008-.176 5.386-.176s4.086.002 5.386.176c1.279.172 2.05.5 2.62 1.069.569.57.896 1.34 1.068 2.619.174 1.3.176 3.008.176 5.386s-.002 4.086-.176 5.386c-.172 1.279-.5 2.05-1.069 2.62-.57.569-1.34.896-2.619 1.068-1.3.174-3.008.176-5.386.176s-4.086-.002-5.386-.176c-1.279-.172-2.05-.5-2.62-1.069-.569-.57-.896-1.34-1.068-2.619-.174-1.3-.176-3.008-.176-5.386s.002-4.086.176-5.386c.172-1.279.5-2.049 1.069-2.619Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default Dashboard;
