import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activeTab: null,
	activeTabOriginalName: null,
};

const navigationReducer = createSlice({
	name: 'navigationReducer',
	initialState,
	reducers: {
		setActiveTab: (state, { payload }) => {
			state.activeTab = payload?.replace?.('Tab', '');
			state.activeTabOriginalName = payload;
		},
	},
	extraReducers: {},
});

export const { reducer } = navigationReducer;
export const setActiveTab = navigationReducer.actions.setActiveTab;
