import { React, useState } from 'react';
import { Form, Button, Empty } from 'antd';
import EditableSettingsCard from '../CompanyInformation/EditableSettingsCard';
import './Contacts.less';

const Contacts = ({ form }) => {
	const [newContact, setNewContact] = useState(false);

	return (
		<div className={'account-settings-contacts'}>
			<h3>
				Contact details{' '}
				<Button type={newContact ? 'primary' : ''} onClick={() => setNewContact(newContact => !newContact)}>
					Add contact
				</Button>
			</h3>
			<span>Add team members who will be responsible for managing this ARLA account.</span>
			<Form prefixCls={'contacts-cards'} form={form}>
				<Form.List name={'contacts'}>
					{(fields, operation) => {
						const contacts = [];
						const newContactEditor = newContact ? (
							<EditableSettingsCard
								operation={operation}
								destroy={() => setNewContact(false)}
								create
								type={'Contact'}
								editing
							/>
						) : null;
						contacts.push(newContactEditor);
						const mappedContacts = fields.map(field => (
							<Form.Item key={field.name} {...field}>
								<EditableSettingsCard type={'Contact'} operation={operation} {...field} />
							</Form.Item>
						));
						const empty = mappedContacts.length === 0 ? <Empty /> : null;
						return [newContactEditor, ...mappedContacts, empty];
					}}
				</Form.List>
			</Form>
		</div>
	);
};

export default Contacts;
