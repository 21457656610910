import React, { useState } from 'react';
import { Button, Empty, Form } from 'antd';
import './CompanyInformation.less';
import EditableSettingsCard from './EditableSettingsCard';

const CompanyInformation = ({ form }) => {
	const [newOffice, setNewOffice] = useState(false);
	/*	const fields = [
		{label: 'Company Legal Name', name: "name"},
		{label: 'Street Address', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"},
		{label: 'Company Legal Name', name: "name"}
	]*/

	/*	const table = <div className="editable-table">
		{fields.map((field, index) => {
			const style = [0, 1, 4, 5, 8, 9].includes(index) ? {backgroundColor: "#fafafa"} : {};
			return <div style={style} key={field.name}><span>{field.label}</span><strong>{form.getFieldValue(field.name)}</strong></div>;
		})}
	</div>*/

	const handleNewOffice = () => setNewOffice(newOffice => !newOffice);

	return (
		<div className="company-information">
			{/*			<div>
				<h3>Company - Main/Billing Address <EditOutlined onClick={handleEditClick} /></h3>
				<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, voluptates!</span>
			</div>
			<Form>
			<div className='company-information__table'>
					{editing ? <BillingAddressForm /> : table}
			</div>
			</Form>
			<Divider/>*/}
			<div>
				<h3 className={'satellite-offices__header'}>
					Satellite Office{' '}
					<Button type={newOffice ? 'primary' : ''} onClick={handleNewOffice}>
						Add office
					</Button>
				</h3>
				<div className={'satellite-offices'}>
					<Form form={form}>
						<Form.List name={'offices'}>
							{(fields, operation) => {
								const offices = [];
								const newOfficeEditor = newOffice ? (
									<EditableSettingsCard
										key={'new'}
										operation={operation}
										destroy={() => setNewOffice(false)}
										create
										type={'Office'}
										editing
									/>
								) : null;
								offices.push(newOfficeEditor);
								const mappedOffices = fields.map((field, index) => (
									<Form.Item key={field.name} {...field}>
										<EditableSettingsCard type={'Office'} operation={operation} {...field} />
									</Form.Item>
								));
								const empty = mappedOffices.length === 0 ? <Empty /> : null;
								return [newOfficeEditor, ...mappedOffices, empty];
							}}
						</Form.List>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default CompanyInformation;
