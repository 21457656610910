import * as React from 'react';
const ValueBased = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={18} height={18} fill="none" {...props}>
		<g clipPath="url(#a)">
			<path
				fill={props.fill || 'currentColor'}
				d="M15.428 0H4.66a.161.161 0 0 0-.16.16v1.126c0 .088.071.16.16.16h9.964v13.822c0 .088.072.16.16.16h1.126c.088 0 .16-.072.16-.16V.643A.642.642 0 0 0 15.429 0Zm-2.572 2.571H2.571a.642.642 0 0 0-.643.643v10.662c0 .17.068.333.189.454l3.481 3.481c.044.044.094.08.149.11v.039h.084c.07.026.145.04.221.04h6.804a.642.642 0 0 0 .643-.643V3.214a.642.642 0 0 0-.643-.643ZM5.745 15.915l-1.73-1.732h1.73v1.732Zm6.308.639H7.03V13.7a.803.803 0 0 0-.803-.804H3.374v-8.88h8.679v12.537Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h18v18H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default ValueBased;
