import React from 'react';
import request from './API/apiHandler';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';

export const accessFile = (ticketId, fileName) => {
	const MIME_TYPE_MAP = {
		csv: 'text/csv',
		pdf: 'application/pdf',
	};

	function showLoading() {
		notification.success({
			message: 'Loading ...',
			key: 'loading',
			duration: 0,
			icon: <LoadingOutlined spin />,
		});
	}

	function hideLoading() {
		notification.destroy('loading');
	}

	showLoading();
	request({
		method: 'get',
		url: `tickets/ats/${ticketId}/${encodeURIComponent(fileName)}`,
		responseType: 'blob',
		notify: true,
	}).then(res => {
		hideLoading();
		const fileExtension = fileName.split('.').pop();

		const file = new Blob([res], { type: MIME_TYPE_MAP[fileExtension] });
		//Build a URL from the file
		const fileURL = URL.createObjectURL(file);

		if (fileExtension === 'pdf') {
			//Open the URL on new Window
			window.open(fileURL);
		} else {
			// Download file
			let link = document.createElement('a');
			link.href = fileURL;
			link.download = fileName;

			link.click();
		}
	});
};
