import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import request from '../../../../utils/API/apiHandler';
import dayjs from '../../../../utils/dateHandler';

const assignedToMe = localStorage.getItem('assignedToMe');

const initialState = {
	events: null,
	loading: false,
	error: false,
	auths: null,
	showMyEvents: JSON.parse(assignedToMe),
	startDate: dayjs().startOf('week').format('YYYY-MM-DD[T00:00:00Z]'),
	endDate: dayjs().endOf('week').format('YYYY-MM-DD[T23:59:00Z]'),
};

export const getAuth = createAsyncThunk('face-to-face/get-auth', (payload, { signal }) => {
	const { platforms, ticketId } = payload;

	return Promise.all(
		platforms.map(platform =>
			request({ url: `calendar/getAuth/${platform}/${ticketId}` })
				.then(resp => ({ loggedIn: resp?.loggedIn, url: resp?.url, platform }))
				.catch(err => {
					notification.error({ message: err });
				}),
		),
	);
});

export const getMeetings = createAsyncThunk('face-to-face/get-meetings', (payload, { getState }) => {
	const { showMyEvents, startDate, endDate } = getState().faceToFaceReducer;
	const { ticketId } = payload;
	return request({ url: `calendar/getMeetings/${ticketId}/`, params: { startDate, endDate, assignedToMe: Boolean(showMyEvents) } });
});

export const getCompany = createAsyncThunk('ticket/getCompany', payload => {
	return request({
		url: '/tickets/ats/getCompanyName',
	});
});

const faceToFace = createSlice({
	name: 'faceToFaceReducer',
	initialState,
	reducers: {
		setShowMyEvents: (state, { payload }) => {
			localStorage.setItem('assignedToMe', payload);
			state.showMyEvents = payload;
		},
		onNavigate: (state, { payload }) => {
			state.startDate = payload.startDate;
			state.endDate = payload.endDate;
		},
	},
	extraReducers: {
		[getMeetings.pending]: state => {
			state.loading = true;
		},
		[getMeetings.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getMeetings.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.events = payload;
		},
		[getAuth.pending]: state => {
			state.loading = true;
		},
		[getAuth.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getAuth.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.auths = payload;
		},
		[getCompany.pending]: state => {
			state.loading = true;
		},
		[getCompany.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getCompany.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.company = payload;
		},
	},
});

export const { reducer, actions } = faceToFace;
