import * as React from 'react';
const Compliance = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={16} height={16} fill="none" {...props}>
		<path
			fill={props.fill || 'currentColor'}
			d="M4.662 3.733a.161.161 0 0 0-.161.16v.965c0 .088.072.16.16.16h7.715c.088 0 .16-.072.16-.16v-.964a.161.161 0 0 0-.16-.161H4.662Zm3.696 2.893H4.662a.161.161 0 0 0-.161.16v.965c0 .088.072.16.16.16h3.697c.088 0 .16-.072.16-.16v-.965a.161.161 0 0 0-.16-.16Zm-.964 9.2h-4.5V1.685h11.25v6.428c0 .089.072.161.16.161h1.125c.089 0 .161-.072.161-.16V.88a.642.642 0 0 0-.643-.643H2.09a.642.642 0 0 0-.643.643v15.75c0 .356.288.643.643.643h5.304c.088 0 .16-.072.16-.16v-1.126a.161.161 0 0 0-.16-.16Zm8.839-1.767H13.34v-.736a2.25 2.25 0 1 0-1.286 0v.736H9.162a.322.322 0 0 0-.322.321v3.054c0 .176.145.321.322.321h7.071a.322.322 0 0 0 .321-.321V14.38a.322.322 0 0 0-.321-.321Zm-4.54-2.893a1.005 1.005 0 1 1 2.01 0 1.005 1.005 0 0 1-2.01 0Zm3.616 5.344h-5.223v-1.206h5.223v1.206Z"
		/>
	</svg>
);
export default Compliance;
