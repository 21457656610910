import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../shared/components/ErrorBoundary/ErrorBoundary';
import { isProduction } from '../utils/functions';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';
import SuspenseLoader from '../shared/components/SuspenseLoader/SuspenseLoader';
import Layout from '../layout/Layout';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import ForgotPass from '../pages/ForgotPass/ForgotPass';

const Home = lazy(() => import('../pages/Home/Home'));
const Department = lazy(() => import('../pages/Department/Department'));
const Login = lazy(() => import('../pages/Login/Login'));
const Details = lazy(() => import('../pages/TicketDetail/TicketDetail'));
const UserMng = lazy(() => import('../pages/UserMng/UserMng'));
const Management = lazy(() => import('../pages/UserMng/Management/Management'));
const Certificate = lazy(() => import('../pages/CertificatePage/CertificatePage'));
const Training = lazy(() => import('../pages/TrainingPage/TrainingPage'));
const SmsTestChat = lazy(() => import('../pages/SmsTestChat/SmsTestChat'));
const ErrorPage = lazy(() => import('../pages/ErrorPage/ErrorPage'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Codes = lazy(() => import('../pages/Codes/Codes'));
const CopilotConversations = lazy(() => import('../features/chat/copilotConversations'));

export default function AppRouter() {
	return (
		<Suspense
			fallback={
				<Layout>
					<SuspenseLoader />
				</Layout>
			}
		>
			<Switch>
				<AuthRoute exact path="/login">
					<Login />
				</AuthRoute>

				<Route exact path="/500">
					<ErrorPage status={500} />
				</Route>

				<Route exact path="/404">
					<ErrorPage status={404} />
				</Route>

				<Route exact path="/error/:status">
					<ErrorPage status={500} />
				</Route>

				<AuthRoute exact path="/forgot-password">
					<ForgotPass />
				</AuthRoute>

				<AuthRoute exact path="/forgot-password/:token">
					<ForgotPass />
				</AuthRoute>

				<ProtectedRoute exact path="/user-management">
					<Layout>
						<ErrorBoundary>
							<UserMng />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/management/:managementPath/">
					<Layout>
						<ErrorBoundary>
							<Management />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/account-settings">
					<Layout>
						<ErrorBoundary>
							<AccountSettings />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/dashboard">
					<Layout>
						<ErrorBoundary>
							<Dashboard></Dashboard>
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				{!isProduction && (
					<ProtectedRoute exact path="/sms-test-chat">
						<Layout>
							<ErrorBoundary>
								<SmsTestChat />
							</ErrorBoundary>
						</Layout>
					</ProtectedRoute>
				)}

				<ProtectedRoute exact path="/chat">
					<Layout>
						<ErrorBoundary>
							<CopilotConversations />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<Route exact path="/:departmentName/ticket/:id/certificate">
					<ErrorBoundary>
						<Certificate />
					</ErrorBoundary>
				</Route>

				<Route exact path="/:departmentName/ticket/:id/certificate/:secondary">
					<ErrorBoundary>
						<Certificate />
					</ErrorBoundary>
				</Route>

				<Route exact path="/:departmentName/ticket/:id/training/:fileName">
					<ErrorBoundary>
						<Training />
					</ErrorBoundary>
				</Route>

				<ProtectedRoute exact path="/:departmentName/ticket/:id">
					<Layout>
						<ErrorBoundary>
							<Details />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/:departmentName">
					<Layout>
						<ErrorBoundary>
							<Department />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/">
					<Layout>
						<ErrorBoundary>
							<Home></Home>
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<ProtectedRoute exact path="/superAdmin/codes">
					<Layout>
						<ErrorBoundary>
							<Codes />
						</ErrorBoundary>
					</Layout>
				</ProtectedRoute>

				<Route exact path="*">
					<ErrorPage status={404} />
				</Route>
			</Switch>
		</Suspense>
	);
}
