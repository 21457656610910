import { notification } from 'antd';

const Notification = (type, title, msg, placement) => {
	if (!type) {
		type = 'success';
	}
	if (!Notification.placements?.includes?.(placement)) {
		placement = 'topRight';
	}
	notification[type]({
		message: title || type?.charAt?.(0)?.toUpperCase?.() + type?.slice?.(1),
		description: msg,
		placement,
	});
};

Notification.placements = ['top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

export default Notification;
