import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { sendEmailForPassChange, sendPasswordChange } from './forgotPassSlice';
import { Button, Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';

import './forgotPass.less';

const ForgotPass = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const [sendEmailForm] = Form.useForm();
	const [newPasswordForm] = Form.useForm();
	const forgotPassword = useSelector(state => state.forgotPassword);
	const errorAlert = e => (
		<span className="login__side__form__inputs__invalid">
			<CloseCircleOutlined /> {e}
		</span>
	);
	async function submitEmailForPassChange() {
		try {
			const { email } = await sendEmailForm.validateFields();

			dispatch(sendEmailForPassChange(email)).then(() => {
				dispatch(push('/login'));
			});
			sendEmailForm.resetFields();
		} catch (err) {}
	}

	const sendEmailCmp = (
		<Form
			form={sendEmailForm}
			name="sendForgotPasswordEmail"
			requiredMark={false}
			className="login__side__form"
			onFinish={submitEmailForPassChange}
			layout="vertical"
		>
			<span className="login__side__form__title">Forgot password?</span>
			<span className="white">We’ll send you reset instructions.</span>
			<Form.Item
				label="Email"
				name="email"
				rules={[
					{
						type: 'email',
						message: errorAlert('Please enter a valid email'),
					},
					{
						required: true,
						message: errorAlert('Please enter a valid email'),
					},
				]}
			>
				<Input placeholder="Enter your email" />
			</Form.Item>

			<Form.Item>
				<Button loading={forgotPassword.loading} style={{ width: '100%' }} type="primary" htmlType="submit">
					Reset Password
				</Button>
			</Form.Item>
			<div>
				<Link to="/login" className="login-back">
					<ArrowLeftOutlined />
					Back to log in
				</Link>
			</div>
		</Form>
	);

	async function submitPasswordChange() {
		try {
			const { password } = await newPasswordForm.validateFields();

			dispatch(sendPasswordChange({ password, token: params.token })).then(() => {
				dispatch(push('/login'));
			});
			newPasswordForm.resetFields();
		} catch (err) {}
	}

	const changePasswordCmp = (
		<Form
			form={newPasswordForm}
			name="sendForgotPasswordEmail"
			requiredMark={false}
			className="login__side__form"
			onFinish={submitPasswordChange}
			layout="vertical"
		>
			<span className="login__side__form__title">Reset password</span>
			<Form.Item
				label="New password"
				name="password"
				rules={[
					{
						required: true,
						message: errorAlert('This field is required'),
					},
					{
						min: 8,
						message: errorAlert('At least 8 characters'),
					},
					{
						pattern: /^(?=.*\d).+$/,
						message: errorAlert('Include at least one number'),
					},
					{
						pattern: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
						message: errorAlert('Use both uppercase and lowercase letters'),
					},
				]}
			>
				<Input.Password placeholder="Enter your password" />
			</Form.Item>

			<Form.Item
				label="Confirm new password"
				dependencies={['password']}
				name="confirm_password"
				rules={[
					{
						required: true,
						message: errorAlert('Please confirm your password'),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(errorAlert('The passwords do not match'));
						},
					}),
				]}
			>
				<Input.Password placeholder="Enter your password" />
			</Form.Item>

			<Form.Item>
				<Button loading={forgotPassword.loading} style={{ width: '100%' }} type="primary" htmlType="submit">
					Reset password
				</Button>
			</Form.Item>
		</Form>
	);

	return (
		<div className="login">
			<div className="login__side">
				<div className="login__side__logo">
					<div className="login__side__logo__icon"></div>
					<div className="login__side__logo__title"></div>
				</div>
				{params.token ? changePasswordCmp : sendEmailCmp}
			</div>
			<div className="login__overview">
				<div className="login__overview__wrapper">
					<span className="login__overview__wrapper__title">An all-in-one operational game changer</span>
					<Carousel autoplay>
						<div className="login__overview__image login__overview__image__1"></div>
					</Carousel>
				</div>
			</div>
		</div>
	);
};
export default ForgotPass;
