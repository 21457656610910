import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import dayjs from '../../../../../utils/dateHandler';
import { DATE_FORMAT } from '../../../../../utils/time';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './expiringDocuments.less';

export default function ExpiringDocuments({ docList, sectionTitle }) {
	const initialDocsData = {
		medicalDocsList: [],
		i9DocsList: [],
	};
	const [selectedGroup, setSelectedGroup] = useState('medical');
	const [docsData, setDocsData] = useState(initialDocsData);

	useEffect(() => {
		loadDocsData();
	}, [docList]);

	const loadDocsData = () => {
		const medicalDocsList = [];
		const i9DocsList = [];
		docList.forEach(val => {
			if (val.category === 'medical') {
				medicalDocsList.push(val);
			} else {
				i9DocsList.push(val);
			}
		});
		setDocsData({
			medicalDocsList,
			i9DocsList,
		});
	};

	return (
		<div key={sectionTitle} className="compliance-documents">
			<div className="compliance-documents__types">
				<div
					className={selectedGroup === 'medical' ? 'active-type' : 'inactive-type'}
					onClick={() => {
						setSelectedGroup('medical');
					}}>
					Medical Documents <span>{docsData?.medicalDocsList.length}</span>
				</div>
				<div
					className={selectedGroup === 'i9' ? 'active-type' : 'inactive-type'}
					onClick={() => {
						setSelectedGroup('i9');
					}}>
					i9 Documents <span>{docsData?.i9DocsList.length}</span>
				</div>
			</div>
			<table>
				<tbody>
					<tr>
						<th>Name</th>
						<th>Date</th>
					</tr>
					{[...(selectedGroup === 'medical' ? docsData.medicalDocsList : docsData.i9DocsList)].map(
						(doc, i) => {
							let tooltipText = '';
							let icon = null;
							const style = {};

							if (dayjs().isSameOrAfter(dayjs(doc.expirationDate))) {
								style.color = 'red';
								tooltipText = 'Expired';
								icon = <ExclamationCircleOutlined />;
							} else if (
								dayjs(doc.expirationDate).isAfter(dayjs()) &&
								dayjs(doc.expirationDate).isBefore(dayjs().add('30', 'day'))
							) {
								style.color = '#d9c638';
								tooltipText = 'Expiring within 30 days';
								icon = <ExclamationCircleOutlined />;
							}

							return (
								<tr key={i}>
									<td>{doc.name}</td>
									<td style={style}>
										<Tooltip title={tooltipText}>
											{dayjs(doc.expirationDate).format(DATE_FORMAT)} {icon}
										</Tooltip>
									</td>
								</tr>
							);
						},
					)}
				</tbody>
			</table>
		</div>
	);
}
