import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ReactComponent as Icon } from '../../assets/images/icons/chat.svg';
import ChatAssistant from './chatAssistant';
import ChatAssistantContext from './chatAssistantContext';
import ChatAssistantMessageDebugger from './chatAssistantMessageDebugger';
import './chatAssistantBadge.less';

const maxAnimations = 5;

export default function ChatAssistantBadge() {
	const { user } = useSelector(state => state.auth);
	const { departmentName } = useParams();
	const [allowDepartment, setAllowDepartment] = useState(false);
	const [opened, setOpened] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [animate, setAnimate] = useState(false);
	const [animations, setAnimations] = useState(0);
	const location = useLocation();

	useEffect(shortcutEffect, []);
	useEffect(departmentEffect, [departmentName]);
	useEffect(animationEffect, [animations]);

	if (location.pathname?.includes?.('/chat')) {
		return null;
	}

	return (
		user && (
			<div
				onMouseEnter={e => setHovered(true)}
				onMouseLeave={e => setHovered(false)}
				className={`chat-badge-container ${opened ? '' : 'closed'} ${!opened && !hovered && !animate ? 'small' : ''} ${allowDepartment ? '' : 'hidden'}`}
			>
				<div className="chat-assistant-section">
					<ChatAssistantContext opened={opened} />
					<ChatAssistantMessageDebugger opened={opened} />
					<ChatAssistant opened={opened} closeAction={() => setOpened(false)} />
				</div>
				<div className="lollipops" onClick={e => setOpened(!opened)}>
					<Icon></Icon>
				</div>
			</div>
		)
	);

	function animateLollipops() {
		if (animations >= maxAnimations) {
			return;
		}

		setAnimations(prevAnimations => prevAnimations + 1);

		// animation
		setAnimate(true);
		setTimeout(() => {
			setAnimate(false);
		}, 2000 * (maxAnimations - animations));
	}

	function animationEffect() {
		// next animation
		const timeout = setTimeout(() => {
			animateLollipops();
		}, 1000 * 60 * animations + 1000); // 1 minute * done animations

		return () => {
			clearTimeout(timeout);
		};
	}

	function shortcutEffect() {
		document.addEventListener('keydown', checkShortcut);
		return () => {
			document.removeEventListener('keydown', checkShortcut);
		};
	}

	function departmentEffect() {
		setAllowDepartment(ChatAssistant.allowedDepartments.includes(departmentName));
	}

	function checkShortcut(e) {
		if (e.ctrlKey && e.shiftKey && e.key === 'C') {
			setOpened(prev => !prev);
		}
	}
}
