import React, { useEffect } from 'react';
import AppRouter from './router/router';
import { replace } from 'connected-react-router';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from './shared/components/ErrorBoundary/ErrorBoundary';
import { checkAppStatus } from './redux/appStatusSlice';
import './App.less';
import useLogger from './shared/hooks/useLogger';
import { socket } from './utils/socketHandler';
import notification from './shared/components/ui/Notification/Notification';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();
	const { activeTab } = useSelector(state => state.navigationReducer);
	const NEW_DEPLOY = 'NEW_DEPLOY';
	useLogger();

	useEffect(async () => {
		const isSupported = await dispatch(checkAppStatus()).then(res => res.payload);
		if (isSupported?.status !== 'ok') {
			dispatch(
				replace(
					`/error/${isSupported?.status || 404}`,
					isSupported?.status
						? {
								status: {
									status: 500,
									title: isSupported?.status,
									subTitle: isSupported?.message,
								},
						  }
						: null,
				),
			);
		}
	}, []);

	const listenAnyMessage = (event, data, ...other) => {
		if (event !== 'UINotification' || !data?.message) {
			return;
		}

		const params = getCurrentParams();

		if (data.ticketId && params?.id !== data.ticketId) {
			return;
		}

		if (data.departmentName && params?.departmentName?.toLowerCase?.() !== data.departmentName?.toLowerCase?.()) {
			return;
		}

		if (data.department && params?.departmentName?.toLowerCase?.() !== data.department?.toLowerCase?.()) {
			return;
		}

		if (data.managementPath && params?.managementPath?.toLowerCase?.() !== data.managementPath?.toLowerCase?.()) {
			return;
		}

		if (data.path && location?.pathname?.toLowerCase?.() !== data.path?.toLowerCase?.()) {
			return;
		}

		if (data.tab && data.tab?.toLowerCase?.() !== activeTab?.toLowerCase?.()) {
			return;
		}

		if (data.stage && data.stage?.toLowerCase?.() !== activeTab?.toLowerCase?.()) {
			return;
		}

		let { message, type = 'success', title, placement } = data;
		const allowedTypes = ['success', 'info', 'warning', 'error', 'warn'];

		if (!allowedTypes.includes(type)) {
			type = 'success';
		}

		notification(type, title || type.charAt(0).toUpperCase() + type.slice(1), message, placement);
	};

	const getCurrentParams = () => {
		const availableRoutes = [
			'/forgot-password/:token',
			'/management/:managementPath',
			'/:departmentName/ticket/:id/certificate',
			'/:departmentName/ticket/:id/training/:fileName',
			'/:departmentName/ticket/:id',
			'/:departmentName',
		];

		const params = {};

		availableRoutes.forEach(path => {
			Object.assign(params, matchPath(location.pathname, path)?.params || {});
		});

		return params;
	};

	const deploy = payload => {
		localStorage.setItem(NEW_DEPLOY, true);
	};

	const reloadIfDeployDone = () => {
		const newDeploy = localStorage.getItem(NEW_DEPLOY);

		if (newDeploy) {
			localStorage.removeItem(NEW_DEPLOY);
			document.location.reload(true);
		}
	};

	useEffect(() => {
		socket.onAny(listenAnyMessage);
		socket.on('deploy', deploy);
		reloadIfDeployDone();
		return () => {
			socket.offAny(listenAnyMessage);
			socket.off('deploy', deploy);
		};
	}, [location.pathname, activeTab]);

	return (
		<>
			<ErrorBoundary>
				<AppRouter />
			</ErrorBoundary>
		</>
	);
}

export default App;
