import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { toSentenceCase } from '@pineapplelab/util';
import './chatAssistantContextItem.less';

export default function ChatAssistantContextItem({ context }) {
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	useEffect(initialEffect, [context]);

	return (
		<Card size="small">
			<strong>{title || context?.name}:</strong>
			<br />
			{content || context?.value}
		</Card>
	);

	function initialEffect() {
		try {
			setTitle(toSentenceCase(context?.name));

			const date = new Date(context?.value).toLocaleString();
			if (date !== 'Invalid Date') {
				setContent(date);
				return;
			}

			if (context?.value === context?.value?.toUpperCase?.()) {
				return; // don't set content if it's all uppercase
			}

			setContent(toSentenceCase(context?.value));
		} catch (error) {}
	}
}
