import dayjs from '../../../../utils/dateHandler';

const dependencyCheck = (currentAnswersHash, dependency, questionGroup, affectedBySiblingsOnly) => {
	const { question, answer } = dependency;
	const answerFromHash = Array.isArray(question)
		? Object.entries(currentAnswersHash)
				.filter(([key, value]) => question.includes(key) && value?.value)
				.map(([, value]) => value?.value)
		: currentAnswersHash[question]?.value;

	let formatAnswerFromHash = answerFromHash
		? (Array.isArray(answerFromHash) ? answerFromHash : [answerFromHash]).flat()
		: [];

	let checkAnswer;
	if (answer === 'Expired') {
		checkAnswer = dayjs(formatAnswerFromHash[0]) < dayjs();
	} else
		checkAnswer = Array.isArray(answer)
			? formatAnswerFromHash?.some(r => answer.includes(r))
			: Boolean(formatAnswerFromHash?.includes(answer));

	if (affectedBySiblingsOnly) {
		return (
			subQuestioRenderCondition(
				currentAnswersHash,
				questionGroup,
				questionGroup.subQuestions.find(val => val._id === question),
			) && checkAnswer
		);
	} else {
		return checkAnswer;
	}
};

export function subQuestioRenderCondition(currentAnswersHash, questionGroup, subQuestion) {
	const showOn = subQuestion?.showOn || {};
	const { dependencies, condition } = showOn;

	//detect if question is only affected by siblings
	//than we need to check if those siblings are rendered
	//example: documentsTab => Tuberculosis (TB) => Chest X-Ray
	let affectedBySiblingsOnly = dependencies?.reduce((acc, currentDep) => {
		return currentDep.question === questionGroup._id ? false : acc;
	}, true);

	if (condition === 'each') {
		return dependencies.every(dependency =>
			dependencyCheck(currentAnswersHash, dependency, questionGroup, affectedBySiblingsOnly),
		);
	} else if (condition === 'any') {
		return dependencies.some(dependency =>
			dependencyCheck(currentAnswersHash, dependency, questionGroup, affectedBySiblingsOnly),
		);
	}
}

export function questionGroupRenderHash(currentAnswersHash, questionGroup) {
	let hash = {};
	questionGroup?.subQuestions?.forEach(subQuestion => {
		hash[subQuestion._id] = subQuestioRenderCondition(currentAnswersHash, questionGroup, subQuestion);
	});
	return hash;
}
