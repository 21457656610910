import queryString from 'query-string';
import request from '../../../utils/API/apiHandler';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from '../../../utils/dateHandler';
import { flattenObject } from '../TicketsList/utils';

const createdAt = { from: dayjs().subtract(6, 'month').utc().hour(0).minute(0).second(0).format(), to: dayjs().utc().hour(23).minute(59).second(59).format() };

const initialState = {
	filters: null,
	filterOptions: {},
	loading: false,
	loadingOptions: false,
	error: false,
	selectedFilters: {
		createdAt,
	},
	reset: false,
};

export const getFilters = createAsyncThunk('filters/get', async ({ params }, { getState }) => {
	const { departmentName } = params;

	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';

	try {
		const resp = await request({
			url: `/${param}/${departmentName}/meta/filters-key`,
			notify: true,
		});

		return { [departmentName]: resp };
	} catch (err) {
		return Promise.reject(err);
	}
});

export const getFilterOptions = createAsyncThunk('filters/load', async ({ params, filterKey, name, search, isNewSearch, page = 1, size }, { getState }) => {
	page = isNewSearch ? 1 : page;
	const { departmentName } = params;
	const { selectedFilters } = getState().departmentFilters;

	let filters = getState().departmentFilters.filterOptions;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	const query = queryString.stringify(flattenObject(selectedFilters), { arrayFormat: 'bracket' });
	const data = { filter: filterKey, search, page, size };

	try {
		const resp = await request({
			url: `/${param}/${departmentName}/meta/filter/?${query ? query : ''}`,
			method: 'POST',
			data,
		});

		return { departmentName, ...data, data: resp, filters, name, page, search, isNewSearch };
	} catch (err) {
		return Promise.reject(err);
	}
});

const filters = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		updateFilter: (state, { payload }) => {
			state.selectedFilters = { ...state.selectedFilters, ...payload };
			state.reset = false;
		},
		resetFilters: (state, { payload }) => {
			const reset = { createdAt: state.selectedFilters?.createdAt };
			if (payload) {
				const prevFilters = JSON.parse(sessionStorage.getItem('filters'));
				sessionStorage.setItem('filters', JSON.stringify({ ...prevFilters, [payload]: reset }));
			}
			state.reset = true;
			state.selectedFilters = reset;
		},
	},
	extraReducers: {
		[getFilters.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getFilters.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.filters = { ...state.filters, ...payload };
			state.loading = false;
		},
		[getFilters.pending]: (state, action) => {
			state.loading = true;
		},
		[getFilterOptions.rejected]: (state, { error }) => {
			state.error = error;
			state.loadingOptions = false;
		},
		[getFilterOptions.fulfilled]: (state, { payload }) => {
			let { departmentName, data, filters, name, page, size, search, isNewSearch } = payload;
			const prevFilter = filters?.[departmentName] || {};
			const preValues = prevFilter?.[name]?.values || [];

			const newValues = data || [];
			const values = isNewSearch ? [...newValues] : [...preValues, ...newValues];
			const hasMore = values.length >= page * size;

			filters = {
				...filters,
				[departmentName]: {
					...prevFilter,
					[name]: {
						nextPage: page + 1,
						hasMore,
						values,
						search,
					},
				},
			};

			state.error = false;
			state.filterOptions = filters;
			state.loadingOptions = false;
		},
		[getFilterOptions.pending]: (state, action) => {
			state.loadingOptions = true;
		},
	},
});

export const { reducer, actions, state } = filters;
