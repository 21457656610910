import React from 'react';
import { Tooltip, List } from 'antd';
import { v4 as uuid } from 'uuid';
import { ExclamationCircleTwoTone, LoadingOutlined, BugTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { copilot } from './copilotSlice';
import './chatAssistantMessage.less';

const { REACT_APP_ENV: env } = process.env;

export default function ChatAssistantMessage({ message, department, latestModel }) {
	const dispatch = useDispatch();
	const { activeTab } = useSelector(state => state.navigationReducer);
	const { logs, debuggingId } = useSelector(state => state.copilot);
	const { user } = useSelector(state => state.auth);

	return (
		<div className={`chat-message ${message.role} ${message.status}`}>
			{message.role !== 'user' && (user?.role === 'developer' || env !== 'production') && message?.id && logs[message?.id] && (
				<Tooltip title="Debug this message.">
					<BugTwoTone
						twoToneColor={debuggingId === message?.id ? null : logs[message?.id].completion?.model?.includes?.('gpt-4') ? '#ae69ff' : '#19c37d'}
						onClick={() => debugMessage(message?.id)}
					/>
				</Tooltip>
			)}
			{(message.content?.text || message.contentToShow || message.content)?.split?.('```')?.map?.((text, i) =>
				i % 2 === 0 ? (
					text
				) : (
					<pre key={i}>
						<label>{text?.split?.('\n')[0]}</label>
						<code>
							{text?.split?.('\n')?.map?.((line, k) => {
								if (k === 0) {
									return null;
								}
								return `\n${line}`;
							})}
						</code>
					</pre>
				),
			)}
			{message.content?.list && (
				<List
					size="small"
					bordered
					header={message.content?.title && <div>{message.content?.title}</div>}
					dataSource={message.content?.list}
					renderItem={item => <List.Item onClick={() => clickOnItem(item, latestModel)}>{item?.label}</List.Item>}
				/>
			)}
			{message.status === 'failed' && (
				<Tooltip title="Error getting the response!" color={'f2b7b7'}>
					<ExclamationCircleTwoTone twoToneColor={'#f2b7b7'} />
				</Tooltip>
			)}
			{message.status === 'loading' && <LoadingOutlined />}
		</div>
	);

	function clickOnItem(item, useLatestModel) {
		const messageObject = { content: item.chat, contentToShow: item.label, role: 'system', status: 'sent', id: uuid(), createdAt: Date.now() };
		dispatch(copilot.addMessage({ ...messageObject, role: 'user', content: item?.label }));
		dispatch(copilot.chat({ message: messageObject, tags: [department, activeTab].filter(value => !!value), latestModel: useLatestModel }));
	}

	function debugMessage(id) {
		dispatch(copilot.debugMessage(debuggingId === id ? null : id));
	}
}
