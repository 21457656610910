import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Result } from 'antd';
import AppLayout from '../../layout/Layout';
import { LAYOUT_STYLE, STATUS_PROPS } from './utils';
import { useLocation } from 'react-router-dom';

const ErrorPage = ({ status }) => {
	const location = useLocation();
	const statusToShow = location?.state?.status || STATUS_PROPS[status];
	const isLoggedIn = useSelector(state => state.auth.loggedIn);
	const ErrorMessage = (
		<Result
			{...statusToShow}
			extra={
				<a href={isLoggedIn ? '/' : '/login'}>
					<Button type="primary">Back Home</Button>
				</a>
			}
		/>
	);

	const layoutWrapper = () => <AppLayout>{ErrorMessage}</AppLayout>;

	return <div style={LAYOUT_STYLE}>{isLoggedIn ? layoutWrapper() : ErrorMessage}</div>;
};

export default ErrorPage;
