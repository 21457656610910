import React, { useState } from 'react';
import { Modal } from 'antd';
import ExpiringDocuments from '../ExpiringDocuments';

import './expiringDocumentsModal.less';

const ExpiringDocumentsModal = ({ documents, title, id }) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<a onClick={() => setVisible(true)}>Show Dates</a>
			{visible ? (
				<Modal
					open={visible}
					title={
						<span className="expiring-tickets-modal-name">
							<p>Expiring Documents - {title} &nbsp;</p>
							<p>#{id.toUpperCase()}</p>
						</span>
					}
					footer={null}
					onCancel={() => setVisible(false)}>
					<ExpiringDocuments docList={documents} sectionTitle="Expiring Documents" />
				</Modal>
			) : null}
		</>
	);
};

export default ExpiringDocumentsModal;
