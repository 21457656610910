const AWS = require('aws-sdk');

export class Logger {
	#errorPatterns = {
		InvalidSequenceTokenException: 'sequenceToken is: ',
		DataAlreadyAcceptedException: 'sequenceToken: ',
	};

	#storageClient;

	#logGroup;

	#sequenceToken;

	#logName;

	#buffer = [];

	#uploadInterval;

	#timer;

	#maxBatchSize;

	#maxConsecutiveErrors;

	#consecutiveErrors = 0;

	#supportedEnvs = ['production', 'stage', 'dev'];

	constructor({
		env,
		cognitoId,
		userId,
		logName = `${new Date().toDateString()} - ${Math.floor(new Date().getHours() / 3)} - ${userId}`,
		attachTo = console,
		methods = ['log', 'info', 'warn', 'debug', 'error'],
		uploadInterval = 5000,
		maxBatchSize = 50,
		awsRegion = cognitoId?.split(':')[0],
		maxConsecutiveErrors = 5,
		showOutput = true,
	} = {}) {
		if (!this.#supportedEnvs.includes(env)) return null;

		AWS.config.region = awsRegion;

		AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: cognitoId });

		this.#storageClient = new AWS.CloudWatchLogs();

		if (env !== 'stage') this.#logGroup = env;
		else this.#logGroup = 'staging';

		this.#logName = logName.toString();

		this.#uploadInterval = uploadInterval;

		this.#maxBatchSize = maxBatchSize;

		this.#maxConsecutiveErrors = maxConsecutiveErrors;

		for (const method of methods) {
			const originalMethod = attachTo[method].bind(attachTo);

			attachTo[method] = (...args) => {
				this.addToBuffer.call(this, [...args]);

				if (!showOutput) return;

				return originalMethod.apply(attachTo, args);
			};
		}
	}

	convertToStr(element) {
		if (element === undefined) {
			return 'undefined';
		}

		return element instanceof Error ? element.stack || element.message : JSON.stringify(element);
	}

	addToBuffer(data) {
		if (!this.#timer) this.#timer = setInterval(this.uploadLogs.bind(this), this.#uploadInterval);
		let message = this.convertToStr(data.shift()).replace(/%s/g, () => this.convertToStr(data.shift()));
		message += '\n' + data.map(this.convertToStr).join('\n');

		if (!message) return;

		this.#buffer.push({
			message,
			timestamp: Date.now(),
		});
	}

	async uploadLogs() {
		if (!this.#buffer.length) return this.stopTimer();

		if (this.#consecutiveErrors >= this.#maxConsecutiveErrors) {
			this.stopTimer();

			this.#buffer = null;

			this.addToBuffer = () => null;

			return console.error(`Shutting down after ${this.#maxConsecutiveErrors} tries`);
		}

		const payload = this.#buffer.splice(0, this.#maxBatchSize);

		try {
			const response = await this.uploadRequest(payload);

			this.#sequenceToken = response.nextSequenceToken;

			this.#consecutiveErrors = 0;
		} catch (error) {
			this.#consecutiveErrors++;

			this.#buffer.unshift(...payload);

			if (error.code === 'ResourceNotFoundException') return await this.createLogStream().catch(console.error);

			this.#sequenceToken = this.extractTokenFromError(error);

			if (!this.#sequenceToken) console.error(error);
		}
	}

	extractTokenFromError(error) {
		const { message, code } = error;

		const pattern = this.#errorPatterns[code];

		if (!pattern) return null;

		const tokenIndex = message.indexOf(pattern) + pattern.length;

		return message.slice(tokenIndex);
	}

	createLogStream() {
		return new Promise((resolve, reject) =>
			this.#storageClient.createLogStream(
				{
					logGroupName: `front_${this.#logGroup}`,
					logStreamName: this.#logName,
				},
				(error, response) => (error ? reject(error) : resolve(response)),
			),
		);
	}

	uploadRequest(logs) {
		return new Promise((resolve, reject) =>
			this.#storageClient.putLogEvents(
				{
					logEvents: logs,
					logGroupName: `front_${this.#logGroup}`,
					logStreamName: this.#logName,
					...(this.#sequenceToken && { sequenceToken: this.#sequenceToken }),
				},
				async (error, response) => (error ? reject(error) : resolve(response)),
			),
		);
	}

	stopTimer() {
		clearInterval(this.#timer);

		this.#timer = null;
	}
}
