import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from './../utils/API/apiHandler';

const initialState = {
	metaInfo: null,
	message: null,
	status: null,
	loading: false,
};

export const handleStatusCheckReq = createAsyncThunk('appStatus/check', async () => {
	const result = await request({
		url: `/app/status`,
		returnError: true,
	});
	return Promise.resolve({ metaInfo: result?.metaInfo, status: result?.status, message: result?.message });
});

export const checkAppStatus = () => dispatch => {
	return dispatch(handleStatusCheckReq());
};

const appStatus = createSlice({
	name: 'appStatus',
	initialState,
	reducers: {},
	extraReducers: {
		[handleStatusCheckReq.rejected]: (state, action) => {
			state.loading = false;
		},
		[handleStatusCheckReq.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.metaInfo = payload.metaInfo;
			state.message = payload.message;
			state.status = payload.status;
		},
		[handleStatusCheckReq.pending]: (state, action) => {
			state.loading = true;
		},
	},
});

export const { reducer, actions } = appStatus;
