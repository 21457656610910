import { store } from '../../../redux/store';
import { actions as callActions } from './callSlice';
// import bandwidth from '../../../utils/bandwidthSetup';
// import nexmo from '../../../utils/nexmoSetup';
import { communicationClient } from '../../../communicationClient';
import notification from '../../../shared/components/ui/Notification/Notification';
import { getProp } from '../../../utils/functions';
import { getCallTitle } from '../../department/department.helper';
import { push } from 'connected-react-router';

export const callStatus = {
	CALLING: 'Calling...',
	RINGING: 'Ringing...',
	LOADING: 'Loading...',
	ANSWERED: 'Answered',
};

export async function handleCall({ title, departmentName, phone, ticket }) {
	const isInCall = store.getState().call.isInCall;

	if (isInCall) return notification('warning', 'Warning', 'Already in call');

	store.dispatch(
		callActions.setCallInfo({
			title,
			phone,
		}),
	);
	store.dispatch(callActions.setSingleCall(true));
	store.dispatch(callActions.setIsInCall(true));

	try {
		const result = await communicationClient.startCall({
			departmentName,
			phone,
			urlExtra: departmentName === 'payroll' && ticket?.company ? ticket?.company : '',
		});
		if (result && result.type === 'error') {
			notification('error', 'Error', result.error.message);
		} else if (result && result.type === 'info') {
			notification('info', 'Info', result.info);
		} else {
			notification('success', 'Success', 'Call successfully completed');
		}
	} catch (err) {
		const showNotification = /^\/.+\/ticket\/.+$/.test(document.location.pathname);

		if (err.type) {
			showNotification && notification('error', 'Server Error', 'Currently not able to make calls');

			await communicationClient.hangUpCall(departmentName);
		} else {
			showNotification && notification('error', 'Error', err.message);
		}
	} finally {
		store.dispatch(callActions.setIsInCall(false));
		store.dispatch(callActions.setCallStatus(null));
		store.dispatch(callActions.setTicketsToCall([]));
		store.dispatch(callActions.setSingleCall(false));
	}
}

export function* callInfoGenerator(tickets, commInfo) {
	let ticketArr = tickets.slice(0);
	let ticketToCall;

	while (ticketArr.length > 0) {
		ticketToCall = ticketArr[0];
		ticketArr = ticketArr.slice(1);

		const phone = getProp(ticketToCall, commInfo.phonePath);

		store.dispatch(callActions.setTicketsToCall(ticketArr));

		const result = yield { ticket: ticketToCall, phone };

		if (result) {
			switch (result.status) {
				case 'CLEAR_ALL':
					ticketArr = [];
					break;
				case 'REMOVE':
					ticketArr = ticketArr.filter(ticket => ticket._id !== result.payload);
					ticketArr.unshift(ticketToCall);
					break;
				case 'BUSY':
					if (ticketArr.length) {
						ticketArr.push(ticketToCall);
					}
					break;
				default:
					break;
			}

			store.dispatch(callActions.setTicketsToCall(ticketArr));
		}
	}
}

export async function startBulkCalls(options) {
	const { calls, commInfo, locationChange, params } = options;
	const generateCallInfo = callInfoGenerator(calls, commInfo);

	communicationClient.setBulkCallGenerator(generateCallInfo);
	let callInfo, result;

	while (!(callInfo = generateCallInfo.next(result)).done) {
		const { value } = callInfo;

		const { ticket } = value;

		store.dispatch(
			callActions.setCallInfo({
				title: getCallTitle(ticket),
				phone: getProp(ticket, commInfo.phonePath),
			}),
		);

		if (locationChange) {
			store.dispatch(
				push(`/${params.departmentName}/ticket/${ticket._id}`, {
					isAutomatedPush: true,
				}),
			);
		}

		store.dispatch(callActions.setIsInCall(true));

		try {
			result = await communicationClient.startCall({
				departmentName: params.departmentName,
				...value,
			});

			if (result && result.type === 'error') {
				notification('error', 'Error', result.error.message);
			} else if (result && result.type === 'info') {
				notification('info', 'Info', result.info);
			}
		} catch (err) {
			const showNotification = /^\/.+\/ticket\/.+$/.test(document.location.pathname);

			if (err.type) {
				showNotification && notification('error', 'Server Error', 'Currently not able to make calls');

				store.dispatch(callActions.setIsInCall(false));

				store.dispatch(callActions.setCallStatus(null));

				await communicationClient.hangUpCall(params.departmentName);
				return generateCallInfo.next({ status: 'CLEAR_ALL' });
			} else {
				showNotification && notification('error', 'Error', err.message);
			}
		}
	}

	store.dispatch(callActions.setIsInCall(false));
	store.dispatch(callActions.setCallStatus(null));
	store.dispatch(callActions.setTicketsToCall([]));

	const showNotification = /^\/.+\/ticket\/.+$/.test(document.location.pathname);

	showNotification && notification('success', 'Success', 'All calls are completed');
}
