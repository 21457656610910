import React, { useState, useEffect } from 'react';
import { CloseCircleTwoTone } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
import { Progress, Tooltip } from 'antd';
import './copilotProgressBar.less';

export default function CopilotProgressBar({ message = 'Starting some active in: <seconds>', seconds = 20, onCanceled, onFinished }) {
	const [tooltip, setTooltip] = useState(message);
	const [canceled, setCanceled] = useState(false);
	const [open, setOpen] = useState(false);
	const [countdown, setCountdown] = useState(seconds);
	// const { user } = useSelector(state => state.auth);

	useEffect(() => {
		const timeout = countDown(countdown);
		return () => clearTimeout(timeout);
	}, [message, open, canceled, countdown]);

	return (
		<Tooltip title={tooltip} color="blue" placement="bottom" open={open}>
			<div className="copilot-progress-bar">
				<Progress percent={((seconds - countdown) * 100) / seconds + 2} status="active" showInfo={false} />
				{
					<Tooltip title="Cancel">
						<CloseCircleTwoTone onClick={e => cancel()} />
					</Tooltip>
				}
			</div>
		</Tooltip>
	);

	function countDown(time) {
		if (canceled) {
			return;
		}
		if (seconds - time > 1 && !open) {
			setOpen(true);
		}
		if (time === 0) {
			setTooltip('Already started!');
			setOpen(false);
			onFinished?.();
			return;
		}
		setTooltip(message.replace('<seconds>', time));
		const value = time - 1;
		return setTimeout(() => setCountdown(value), 1000);
	}

	function cancel() {
		setCanceled(true);
		onCanceled?.();
	}
}
