import React, { useState } from 'react';
import { Modal } from 'antd';
import DeniedStagesList from './DeniedStagesList';

export default function DeniedStagesModal({ record, applicationID, fullName }) {
	const [visible, setVisible] = useState(false);
	const { stages } = record;

	const stagesDenied = stages?.find(val => val.name === 'Approval')?.approvalData?.find(val => val.name === 'Denied by HR')?.stagesDenied;

	return (
		<>
			{stagesDenied?.length ? <a onClick={() => setVisible(true)}>Show Stages</a> : <span>No Data</span>}
			{visible ? (
				<Modal
					open={visible}
					title={
						<span className="expiring-tickets-modal-name">
							<p>Applicant Name - {fullName} &nbsp;</p>
							<p>#{applicationID.toUpperCase()}</p>
						</span>
					}
					footer={null}
					onCancel={() => setVisible(false)}>
					<DeniedStagesList stagesDenied={stagesDenied} />
				</Modal>
			) : null}
		</>
	);
}
