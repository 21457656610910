import * as React from 'react';
const ProgressUpload = props => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
		<path
			fill={props.fill || 'currentColor'}
			d="M18.308 10.833c-.391 3.942-3.516 7.084-7.475 7.459a8.313 8.313 0 0 1-9.108-7.459h1.667c.358 3.034 2.766 5.417 5.8 5.8a6.662 6.662 0 0 0 7.433-5.8h1.683Zm-.025-1.666h-1.666a6.663 6.663 0 0 0-1.4-3.25l1.233-1.192a8.19 8.19 0 0 1 1.833 4.442ZM15.275 3.55l-1.192 1.192a6.68 6.68 0 0 0-3.25-1.367V1.708a8.237 8.237 0 0 1 4.442 1.842ZM9.167 1.717v1.666a6.669 6.669 0 0 0-3.25 1.359L4.725 3.558a8.366 8.366 0 0 1 4.442-1.841Zm-4.475 4.2a6.669 6.669 0 0 0-1.359 3.25H1.667A8.328 8.328 0 0 1 3.55 4.725l1.142 1.192ZM13.75 10 10 6.25v2.917H6.667v1.666H10v2.917L13.75 10Z"
		/>
	</svg>
);
export default ProgressUpload;
