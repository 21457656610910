import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import request from '../../../utils/API/apiHandler';

const initialState = {
	headers: null,
	loading: false,
	error: false,
	selectedTab: null,
};

export const getHeaders = createAsyncThunk('headers/get', async ({ params }, { dispatch, rejectWithValue }) => {
	const { departmentName } = params;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';

	dispatch(actions.applyLocalHeaders({ department: departmentName }));

	try {
		return await request({
			url: `/${param}/${departmentName}/meta/headers`,
			notify: true,
		}).then(data => {
			localStorage.setItem(`${departmentName}:headers`, JSON.stringify(data));
			return data;
		});
	} catch (err) {
		dispatch(push('/500'));
		return Promise.reject(err);
	}
});

const headers = createSlice({
	name: 'headers',
	initialState,
	reducers: {
		switchTab: (state, { payload }) => {
			if (Array.isArray(payload)) {
				state.selectedTab = payload[0];
				state.activeFilter = payload[1].filter;
				return;
			}

			state.selectedTab = payload;

			const header = state?.headers?.[payload];

			state.activeFilter = header ? header.filter : payload;
		},
		applyLocalHeaders: (state, { payload }) => {
			if (!payload?.department) {
				return;
			}
			let headers = null;

			try {
				headers = JSON.parse(localStorage.getItem(`${payload?.department}:headers`));
			} catch (error) {}

			if (!headers) {
				return;
			}

			applyHeaderPayload(state, headers, true);
		},
	},
	extraReducers: {
		[getHeaders.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getHeaders.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			applyHeaderPayload(state, payload);
		},
		[getHeaders.pending]: (state, action) => {
			state.loading = true;
		},
	},
});

function applyHeaderPayload(state, payload, forceActiveFilter = false) {
	state.headers = payload;

	const activeTab = payload[state.selectedTab];

	if (activeTab) {
		if (forceActiveFilter || state.activeFilter?._headers !== activeTab.filter?._headers) {
			state.activeFilter = activeTab.filter;
		}
		return;
	}

	state.selectedTab = Object.keys(payload)[0];
	state.activeFilter = Object.values(payload)[0].filter;
}

export const { reducer, actions, state } = headers;
