import React from 'react'

const UserSmallIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="path-1-inside-1_8413_23991" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.5 6C5.5 5.33696 5.76339 4.70107 6.23223 4.23223C6.70107 3.76339 7.33696 3.5 8 3.5C8.66304 3.5 9.29893 3.76339 9.76777 4.23223C10.2366 4.70107 10.5 5.33696 10.5 6C10.5 6.66304 10.2366 7.29893 9.76777 7.76777C9.29893 8.23661 8.66304 8.5 8 8.5C7.33696 8.5 6.70107 8.23661 6.23223 7.76777C5.76339 7.29893 5.5 6.66304 5.5 6ZM8 4.5C7.60218 4.5 7.22064 4.65804 6.93934 4.93934C6.65804 5.22064 6.5 5.60218 6.5 6C6.5 6.39782 6.65804 6.77936 6.93934 7.06066C7.22064 7.34196 7.60218 7.5 8 7.5C8.39782 7.5 8.77936 7.34196 9.06066 7.06066C9.34196 6.77936 9.5 6.39782 9.5 6C9.5 5.60218 9.34196 5.22064 9.06066 4.93934C8.77936 4.65804 8.39782 4.5 8 4.5Z" />
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 6C5.5 5.33696 5.76339 4.70107 6.23223 4.23223C6.70107 3.76339 7.33696 3.5 8 3.5C8.66304 3.5 9.29893 3.76339 9.76777 4.23223C10.2366 4.70107 10.5 5.33696 10.5 6C10.5 6.66304 10.2366 7.29893 9.76777 7.76777C9.29893 8.23661 8.66304 8.5 8 8.5C7.33696 8.5 6.70107 8.23661 6.23223 7.76777C5.76339 7.29893 5.5 6.66304 5.5 6ZM8 4.5C7.60218 4.5 7.22064 4.65804 6.93934 4.93934C6.65804 5.22064 6.5 5.60218 6.5 6C6.5 6.39782 6.65804 6.77936 6.93934 7.06066C7.22064 7.34196 7.60218 7.5 8 7.5C8.39782 7.5 8.77936 7.34196 9.06066 7.06066C9.34196 6.77936 9.5 6.39782 9.5 6C9.5 5.60218 9.34196 5.22064 9.06066 4.93934C8.77936 4.65804 8.39782 4.5 8 4.5Z" fill="#0D0D0D" />
            <path d="M8 3.5V2.5V3.5ZM10.5 6H11.5H10.5ZM8 4.5V3.5V4.5ZM6.5 6C6.5 5.60218 6.65804 5.22064 6.93934 4.93934L5.52513 3.52513C4.86875 4.1815 4.5 5.07174 4.5 6H6.5ZM6.93934 4.93934C7.22064 4.65804 7.60218 4.5 8 4.5V2.5C7.07174 2.5 6.1815 2.86875 5.52513 3.52513L6.93934 4.93934ZM8 4.5C8.39782 4.5 8.77936 4.65804 9.06066 4.93934L10.4749 3.52513C9.8185 2.86875 8.92826 2.5 8 2.5V4.5ZM9.06066 4.93934C9.34196 5.22064 9.5 5.60218 9.5 6H11.5C11.5 5.07174 11.1313 4.1815 10.4749 3.52513L9.06066 4.93934ZM9.5 6C9.5 6.39782 9.34196 6.77936 9.06066 7.06066L10.4749 8.47487C11.1313 7.8185 11.5 6.92826 11.5 6H9.5ZM9.06066 7.06066C8.77936 7.34196 8.39782 7.5 8 7.5V9.5C8.92826 9.5 9.8185 9.13125 10.4749 8.47487L9.06066 7.06066ZM8 7.5C7.60218 7.5 7.22064 7.34196 6.93934 7.06066L5.52513 8.47487C6.1815 9.13125 7.07174 9.5 8 9.5V7.5ZM6.93934 7.06066C6.65804 6.77936 6.5 6.39782 6.5 6H4.5C4.5 6.92826 4.86875 7.8185 5.52513 8.47487L6.93934 7.06066ZM8 3.5C7.33696 3.5 6.70107 3.76339 6.23223 4.23223L7.64645 5.64645C7.74022 5.55268 7.86739 5.5 8 5.5V3.5ZM6.23223 4.23223C5.76339 4.70107 5.5 5.33696 5.5 6H7.5C7.5 5.86739 7.55268 5.74022 7.64645 5.64645L6.23223 4.23223ZM5.5 6C5.5 6.66304 5.76339 7.29893 6.23223 7.76777L7.64645 6.35355C7.55268 6.25978 7.5 6.13261 7.5 6H5.5ZM6.23223 7.76777C6.70107 8.23661 7.33696 8.5 8 8.5V6.5C7.86739 6.5 7.74022 6.44732 7.64645 6.35355L6.23223 7.76777ZM8 8.5C8.66304 8.5 9.29893 8.23661 9.76777 7.76777L8.35355 6.35355C8.25978 6.44732 8.13261 6.5 8 6.5V8.5ZM9.76777 7.76777C10.2366 7.29893 10.5 6.66304 10.5 6H8.5C8.5 6.13261 8.44732 6.25978 8.35355 6.35355L9.76777 7.76777ZM10.5 6C10.5 5.33696 10.2366 4.70107 9.76777 4.23223L8.35355 5.64645C8.44732 5.74022 8.5 5.86739 8.5 6H10.5ZM9.76777 4.23223C9.29893 3.76339 8.66304 3.5 8 3.5V5.5C8.13261 5.5 8.25978 5.55268 8.35355 5.64645L9.76777 4.23223Z" fill="#0D0D0D" mask="url(#path-1-inside-1_8413_23991)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.833496 7.99992C0.833496 4.04192 4.04216 0.833252 8.00016 0.833252C11.9582 0.833252 15.1668 4.04192 15.1668 7.99992C15.1668 11.9579 11.9582 15.1666 8.00016 15.1666C4.04216 15.1666 0.833496 11.9579 0.833496 7.99992ZM8.00016 1.83325C6.78196 1.83331 5.59106 2.19418 4.57773 2.87032C3.56439 3.54647 2.77402 4.5076 2.30634 5.63246C1.83865 6.75731 1.71461 7.99548 1.94985 9.19076C2.1851 10.386 2.7691 11.4849 3.62816 12.3486C3.74816 11.6999 3.98483 11.0886 4.45883 10.5966C5.16883 9.86059 6.30283 9.49992 8.00016 9.49992C9.6975 9.49992 10.8315 9.86059 11.5408 10.5966C12.0155 11.0886 12.2515 11.6999 12.3722 12.3493C13.2315 11.4856 13.8157 10.3867 14.051 9.19129C14.2864 7.9959 14.1624 6.75757 13.6947 5.63258C13.227 4.50759 12.4365 3.54636 11.423 2.87018C10.4096 2.194 9.2185 1.83318 8.00016 1.83325ZM11.4628 13.1033C11.3948 12.2953 11.2195 11.7033 10.8215 11.2906C10.3882 10.8419 9.58616 10.4999 8.00016 10.4999C6.41416 10.4999 5.61216 10.8419 5.17883 11.2906C4.78083 11.7039 4.6055 12.2953 4.5375 13.1039C5.55855 13.7984 6.76532 14.1687 8.00016 14.1666C9.23506 14.1685 10.4418 13.7979 11.4628 13.1033Z" fill="#0D0D0D" />
        </svg>
    )
}

export default UserSmallIcon;
