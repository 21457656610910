import * as React from 'react';
const Intake = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={18} height={18} fill="none" {...props}>
		<path
			fill={props.fill || 'currentColor'}
			d="M13.963 5.44A4.981 4.981 0 0 0 8.98.456a4.981 4.981 0 0 0-2.913 9.024l-.018.008a7.486 7.486 0 0 0-2.397 1.62 7.502 7.502 0 0 0-1.615 2.4 7.506 7.506 0 0 0-.59 2.774.16.16 0 0 0 .16.165h1.203c.087 0 .16-.07.161-.157.04-1.55.661-3.003 1.76-4.104a5.955 5.955 0 0 1 4.249-1.766 4.981 4.981 0 0 0 4.982-4.982ZM8.98 8.894a3.455 3.455 0 1 1 0-6.91 3.455 3.455 0 0 1 0 6.91Zm2.11 4.44h5.303c.088 0 .16-.073.16-.162V12.05a.161.161 0 0 0-.16-.161h-3.546l.948-1.207a.163.163 0 0 0 .034-.099.161.161 0 0 0-.16-.16H12.21a.328.328 0 0 0-.253.122l-1.376 1.75a.645.645 0 0 0 .508 1.04Zm4.82 1.285h-5.303a.161.161 0 0 0-.16.16v1.126c0 .088.072.16.16.16h3.546l-.948 1.208a.162.162 0 0 0-.035.098c0 .089.073.16.161.16h1.459c.098 0 .19-.045.253-.122l1.376-1.75a.645.645 0 0 0-.508-1.04Z"
		/>
	</svg>
);
export default Intake;
