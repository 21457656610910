import React from 'react'

export const ViewIcon = ({ size = 16, color = 'black' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 5.5C7.33696 5.5 6.70107 5.76339 6.23223 6.23223C5.76339 6.70107 5.5 7.33696 5.5 8C5.5 8.66304 5.76339 9.29893 6.23223 9.76777C6.70107 10.2366 7.33696 10.5 8 10.5C8.66304 10.5 9.29893 10.2366 9.76777 9.76777C10.2366 9.29893 10.5 8.66304 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5ZM6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5C8.39782 6.5 8.77936 6.65804 9.06066 6.93934C9.34196 7.22064 9.5 7.60218 9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5C7.60218 9.5 7.22064 9.34196 6.93934 9.06066C6.65804 8.77936 6.5 8.39782 6.5 8Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99998 2.16666C4.99065 2.16666 2.96331 3.96932 1.78665 5.49799L1.76598 5.52532C1.49931 5.87132 1.25465 6.18932 1.08865 6.56532C0.910646 6.96866 0.833313 7.40799 0.833313 7.99999C0.833313 8.59199 0.910646 9.03132 1.08865 9.43466C1.25531 9.81066 1.49998 10.1293 1.76598 10.4747L1.78731 10.502C2.96331 12.0307 4.99065 13.8333 7.99998 13.8333C11.0093 13.8333 13.0366 12.0307 14.2133 10.502L14.234 10.4747C14.5006 10.1293 14.7453 9.81066 14.9113 9.43466C15.0893 9.03132 15.1666 8.59199 15.1666 7.99999C15.1666 7.40799 15.0893 6.96866 14.9113 6.56532C14.7446 6.18932 14.5 5.87132 14.234 5.52532L14.2126 5.49799C13.0366 3.96932 11.0093 2.16666 7.99998 2.16666ZM2.57998 6.10799C3.66531 4.69666 5.43331 3.16666 7.99998 3.16666C10.5666 3.16666 12.334 4.69666 13.42 6.10799C13.7133 6.48799 13.884 6.71466 13.9966 6.96932C14.102 7.20799 14.1666 7.49932 14.1666 7.99999C14.1666 8.50066 14.102 8.79199 13.9966 9.03066C13.884 9.28532 13.7126 9.51199 13.4206 9.89199C12.3333 11.3033 10.5666 12.8333 7.99998 12.8333C5.43331 12.8333 3.66598 11.3033 2.57998 9.89199C2.28665 9.51199 2.11598 9.28532 2.00331 9.03066C1.89798 8.79199 1.83331 8.50066 1.83331 7.99999C1.83331 7.49932 1.89798 7.20799 2.00331 6.96932C2.11598 6.71466 2.28798 6.48799 2.57998 6.10799Z" fill={color} />
        </svg>
    )
}

export default ViewIcon;
