export const flattenObject = obj => {
	const ret = {};
	for (const key of Object.keys(obj)) {
		if (typeof obj[key] === 'object' && obj[key]?.length === undefined && Object.keys(obj[key]).length > 0) {
			for (const key2 of Object.keys(obj[key])) {
				ret[`${key}[${key2}]`] = obj[key][key2];
			}
		} else {
			ret[key] = obj[key];
		}
	}
	return ret;
};

function getLuminance(hexaColor) {
	hexaColor = String(hexaColor).replace(/[^0-9a-f]/gi, '');
	if (hexaColor.length < 6) {
		hexaColor = hexaColor[0] + hexaColor[0] + hexaColor[1] + hexaColor[1] + hexaColor[2] + hexaColor[2];
	}

	const red = parseInt(hexaColor.slice(0, 2), 16) / 255;
	const green = parseInt(hexaColor.slice(2, 4), 16) / 255;
	const blue = parseInt(hexaColor.slice(4, 6), 16) / 255;

	const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

	return { baseLuminance: luminance * 100, hexaColor };
}

export const reduceColorLuminance = color => {
	const { baseLuminance, hexaColor } = getLuminance(color);
	const targetLuminancePercentage = 20;
	if (baseLuminance < targetLuminancePercentage) {
		return color;
	}

	const lumReduction = (baseLuminance - targetLuminancePercentage) / 100;

	let colorResult = '#',
		colorComponent;

	for (let i = 0; i < 3; i++) {
		colorComponent = parseInt(hexaColor.slice(i * 2, i * 2 + 2), 16);
		colorComponent = Math.round(Math.min(Math.max(0, colorComponent - colorComponent * lumReduction), 255)).toString(16);
		colorResult += ('00' + colorComponent).slice(-2);
	}

	return colorResult;
};
