/**
 * A wrapper for <Route> that redirects to Home screen if you are authenticated
 * but you are trying to access pages for authentication
 */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '../pages/Login/authSlice';

export default function AuthRoute({ children, ...rest }) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout());
	}, []);

	return (
		<Route
			{...rest}
			render={() => {
				return children;
			}}
		/>
	);
}
