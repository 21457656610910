import { Modal } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DATE_FORMAT } from '../../../../utils/time';
import HhauType from '../../../TicketDetail/TabsAts/OrientationTab/HhauType';

import './OrientationModal.less';

const OrientationModal = ({ orientationStage }) => {
	const [showModal, setShowModal] = useState(false);

	const getDate = () => {
		let date = '';
		if (orientationStage.from) {
			date = dayjs(orientationStage.from).format(DATE_FORMAT);
		} else if (orientationStage.to) {
			date = dayjs(orientationStage.to.format(DATE_FORMAT));
		}
		return date;
	};

	const InPerson = () => (
		<>
			<div className="group">
				<span className="label">Location</span>
				<span className="value">{orientationStage.location || 'No Data'}</span>
			</div>
			<div className="group">
				<span className="label">Date</span>
				<span className="value">{getDate() || 'No Data'}</span>
			</div>
		</>
	);

	return (
		<>
			<a onClick={() => setShowModal(true)}>{orientationStage?.orientationType || 'No Data'}</a>
			<Modal title="Orientation Details" open={orientationStage?.orientationType && showModal} onCancel={() => setShowModal(false)} footer={null}>
				{orientationStage?.orientationType === 'In Person' ? <InPerson /> : <HhauType tabStageObj={orientationStage} />}
			</Modal>
		</>
	);
};

export default OrientationModal;
